import React from 'react';
import styled from 'styled-components';

const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  background-color: #fff;
  padding: 10px;
  margin: 5px 0;
  h5 {
    margin: 5px 0 10px 0;
    font-size: 1rem;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    text-transform: uppercase;
  }
`;

const FilterGroup = ({ title, children }) => {
  return (
    <Root>
      {title && <h5>{title}</h5>}
      {children}
    </Root>
  );
};

export default FilterGroup;
