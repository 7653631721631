import React from 'react';
import styled from 'styled-components';
import { Table } from '../components/tables/table';
import translate from '../providers/i18n/translate';
import { ThemeContext } from '../providers/theme';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { API_BACKEND } from '../config';
import AddIcon from '@mui/icons-material/Add';

/* Styled components */
const Root = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 50px 1fr;
  height: 100%;
  width: 100%;
  padding: 0 20px;
  @media (min-width: 1024px) {
    padding: 0 50px;
  }
`;

const Title = styled.h2`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  color: ${(props) => (props.color ? props.color : '#000')};
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`;

const Content = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const NewIcon = styled.div`
  position: sticky;
  right: 20px;
  top: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 50%;
  background-color: ${(props) => (props.bg ? props.bg : '7A7A7A')};
  color: ${(props) => (props.color ? props.color : '#FFF')};
  cursor: pointer;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  &:hover {
    transform: scale(1.1);
  }
  z-index: 99;
  @media (min-width: 1024px) {
    right: 50px;
    bottom: 50px;
  }
`;

export default function PostsPage() {
  const theme = React.useContext(ThemeContext);

  const tableRef = React.useRef();

  const navigate = useNavigate();

  const intl = useIntl();

  const columnsTable = [
    { title: 'ID', field: 'id', width: '10%', order: false, hidden: false },
    { title: translate('title'), field: 'title', order: false, width: '50%', hidden: false },
    {
      title: translate('type'),
      field: 'type',
      order: false,
      width: '20%',
      hidden: false,
      render: (d) => d.type.description,
    },
    { title: translate('author'), field: 'author', width: '20%', hidden: false, mobile: false },
    { title: translate('actions'), actions: true, width: 'auto', hidden: false },
  ];

  const handleNewItem = (data) => {
    navigate(`/admin/newpost`);
  };

  const handleEditItem = (data) => {
    navigate(`/admin/posts/${data.id}`);
  };

  const handleDeleteItem = (data) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(intl.formatMessage({ id: 'confirm delete' }))) {
      axios
        .delete(`${API_BACKEND}/posts/${data.id}`)
        .then((result) => {
          tableRef.current.clean();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const actionsTable = {
    edit: {
      icon: theme.icons.edit,
      tooltip: 'Editar',
      onClick: handleEditItem,
      color: theme.palette.principal,
      hoverColor: theme.palette.accent,
      hidden: false,
    },
    delete: {
      icon: theme.icons.delete,
      tooltip: 'Eliminar',
      onClick: handleDeleteItem,
      color: theme.palette.principal,
      hoverColor: theme.palette.accent,
      hidden: false,
    },
  };

  return (
    <Root>
      <Title color={theme.palette.principal}>{translate('Posts')}</Title>
      <Content>
        <Table
          ref={tableRef}
          columns={columnsTable}
          actionsTable={actionsTable}
          viewTable="list"
          disablePlain
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(`${API_BACKEND}/posts`)
                .then((result) => {
                  resolve({
                    rows: result.data,
                    total: result.data.length,
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
        ></Table>
        <NewIcon color={theme.palette.base} bg={theme.palette.accent} onClick={handleNewItem}>
          <AddIcon />
        </NewIcon>
      </Content>
    </Root>
  );
}
