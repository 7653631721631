import { TextField } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import 'react-color-palette/lib/css/styles.css';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useIntl } from 'react-intl';

const InputGroup = styled.div`
  width: 15%;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;

const PaddingGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
`;

const PaddingSelector = ({
  editPadding,
  name,
  handlePaddingChange,
  handlePaddingCheckedChange,
}) => {
  const intl = useIntl();

  const handleChangeCheck = (e) => {
    handlePaddingCheckedChange(name, e.target.name, e.target.checked);
  };

  const handleChange = (e) => {
    handlePaddingChange(name, e.target.name, e.target.value);
  };

  return (
    <PaddingGroup>
      <FormControlLabel
        control={
          <Checkbox
            name="usePadding"
            checked={editPadding?.usePadding}
            onChange={handleChangeCheck}
          />
        }
        label={intl.formatMessage({ id: name })}
      />
      {editPadding?.usePadding && (
        <>
          <InputGroup>
            <TextField
              id="paddingTop"
              name="paddingTop"
              onChange={handleChange}
              value={editPadding?.paddingTop}
              label={intl.formatMessage({ id: 'top' })}
              variant="outlined"
              margin="normal"
              size="small"
              type="number"
              min="0"
              required
            />
          </InputGroup>
          <InputGroup>
            <TextField
              id="paddingBottom"
              name="paddingBottom"
              onChange={handleChange}
              value={editPadding?.paddingBottom}
              label={intl.formatMessage({ id: 'bottom' })}
              variant="outlined"
              margin="normal"
              size="small"
              type="number"
              min="0"
              required
            />
          </InputGroup>
          <InputGroup>
            <TextField
              id="paddingLeft"
              name="paddingLeft"
              onChange={handleChange}
              value={editPadding?.paddingLeft}
              label={intl.formatMessage({ id: 'left' })}
              variant="outlined"
              margin="normal"
              size="small"
              type="number"
              min="0"
              required
            />
          </InputGroup>
          <InputGroup>
            <TextField
              id="paddingRight"
              name="paddingRight"
              onChange={handleChange}
              value={editPadding?.paddingRight}
              label={intl.formatMessage({ id: 'right' })}
              variant="outlined"
              margin="normal"
              size="small"
              type="number"
              min="0"
              required
            />
          </InputGroup>
        </>
      )}
    </PaddingGroup>
  );
};

export default PaddingSelector;
