import styled from 'styled-components';

const Root = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.fieldsNumber ? `repeat(${props.fieldsNumber}, 1fr) 50px` : 'auto'};
  width: 100%;
  gap: 5px;
`;

const Field = styled.span`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid black;
  background: #fff;
`;

const DeleteRow = styled.button`
  background: red;
  color: #fff;
  box-shadow: none;
  border: none;
  padding: 2px;
  cursor: pointer;
`;

const RowContent = styled.input`
  width: 100%;
  height: 100%;
  padding: 5px;
`;

const RowDetails = ({ id, rowindex, data, onDeleteRow, changeCell }) => {
  const onDelete = () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('¿Desea eliminar esta fila?')) {
      onDeleteRow(rowindex);
    }
  };

  const onChangeCell = (e) => {
    const { value } = e.target;
    const { column } = e.target.dataset;
    changeCell(rowindex, column, value);
  };

  return data && data.length > 0 ? (
    <Root fieldsNumber={data.length}>
      {data.map((field, index) => (
        <Field key={`${id}_field_${index}`}>
          <RowContent data-column={index} value={field} onChange={onChangeCell} />
        </Field>
      ))}
      <DeleteRow onClick={onDelete}>X</DeleteRow>
    </Root>
  ) : null;
};

export default RowDetails;
