import styled from 'styled-components';
import React from 'react';
import { useIntl } from 'react-intl';
import { TextField } from '@mui/material';
import translate from '../../providers/i18n/translate';
import { v4 as uuidv4 } from 'uuid';

const Root = styled.form`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: auto;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
`;

const StyledTextfield = styled(TextField)`
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : 'inherit')};
  }
`;

const Title = styled.h4`
  font-size: 1.5rem;
  width: 100%;
  color: ${(props) => props.theme.palette.details};
  border-bottom: 1px solid ${(props) => props.theme.palette.details};
  height: auto;
  text-transform: uppercase;
`;

const InputError = styled.span`
  width: fit-content;
  height: ${(props) => (props.show ? 'auto' : 0)};
  overflow: hidden;
  color: ${(props) => props.theme.palette.error};
  padding: ${(props) => (props.show ? '8px 10px' : 0)};
  opacity: ${(props) => (props.show ? 1 : 0)};
  border: ${(props) => props.theme.palette.error};
  background: ${(props) => `${props.theme.palette.error}10`};
  border-radius: 8px;
  margin-bottom: 10px;
  font-size: 0.875rem;
  transition: all 300ms ease-in-out;
`;

const NewItem = ({ onClose, handleEdit, handleNew, editData }) => {
  const intl = useIntl();

  const [errors, setErrors] = React.useState({
    title: {
      value: false,
      text: intl.formatMessage({ id: 'title must not be empty' }),
      validate: (value) => value === null || value === '', // returns true if there is an error and false if there is not
    },
    content: {
      value: false,
      text: intl.formatMessage({ id: 'content must not be empty' }),
      validate: (value) => value === null || value === '', // returns true if there is an error and false if there is not
    },
  });

  const [data, setData] = React.useState(
    editData
      ? editData
      : {
          title: '',
          text: '',
        }
  );

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const validateForm = () => {
    let foundError = false;
    const newErrors = {};
    Object.keys(errors).forEach((err) => {
      if (!foundError) {
        foundError = errors[err].validate(data[err]);
      }
      const val = errors[err].validate(data[err]);
      newErrors[err] = {
        ...errors[err],
        value: val,
      };
      setErrors({
        ...errors,
        ...newErrors,
      });
    });
    return !foundError;
  };

  const onNew = (e) => {
    e.preventDefault();
    if (validateForm()) {
      handleNew({
        title: data.title,
        text: data.text,
        id: uuidv4(),
      });
      onClose();
    }
  };

  const onEdit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      handleEdit(data);
      onClose();
    }
  };

  return (
    <Root>
      <Title>{editData ? translate('edit item') : translate('new item')}</Title>
      <StyledTextfield
        id="title"
        type="text"
        name="title"
        onChange={handleChange}
        value={data.title}
        label={intl.formatMessage({ id: 'title' })}
        variant="outlined"
        width="100%"
        margin="dense"
        size="small"
        required
      />
      <InputError show={errors.title.value}>{errors.title.text}</InputError>

      <StyledTextfield
        id="content"
        name="text"
        onChange={handleChange}
        value={data.text}
        label={intl.formatMessage({ id: 'content' })}
        variant="outlined"
        width="100%"
        margin="dense"
        size="small"
        required
        multiline
        rows={4}
      />
      <InputError show={errors.content.value}>{errors.content.content}</InputError>
      {editData ? <button onClick={onEdit}>EDITAR</button> : <button onClick={onNew}>CREAR</button>}
    </Root>
  );
};

export default NewItem;
