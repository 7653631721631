import React from 'react';
import styled from 'styled-components';
import translate from '../providers/i18n/translate';

/* Styled components */
const Root = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.palette.details};
`;

export default function AdminPage() {
  return (
    <Root>
      <h1>{translate('Welcome')}</h1>
    </Root>
  );
}
