import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import translate from '../../providers/i18n/translate';
import TextField from '@mui/material/TextField';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../store/action-creators';
import { alertConstants } from '../../store/reducers/messageBar/alertConstants';
import axios from 'axios';
import { API_BACKEND } from '../../config';
import { useAuth } from '../../providers/authentication';
import { decodeToken } from '../../hooks/jwt';
import SelectInput from '../inputs/SelectInput';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px;
`;

const Header = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid black;
`;

const Title = styled.h2`
  text-transform: uppercase;
`;

const Actions = styled.div`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: flex-end;
`;

const ActionButton = styled.button`
  background: transparent;
  border: none;
  text-transform: capitalize;
  border: 1px solid rgba(0, 0, 0, 0.5);
  padding: 8px 10px;
  cursor: pointer;
  border-radius: 8px;
  &:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  height: calc(100% - 50px);
  flex-direction: column;
  gap: 20px;
  padding: 20px 0;
`;

const StyledTextfield = styled(TextField)`
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : 'inherit')};
  }
`;

const InputData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : 'inherit')};
  }
`;

const InputError = styled.span`
  width: fit-content;
  height: ${(props) => (props.show ? 'auto' : 0)};
  overflow: hidden;
  color: ${(props) => props.theme.palette.error};
  padding: ${(props) => (props.show ? '8px 10px' : 0)};
  opacity: ${(props) => (props.show ? 1 : 0)};
  border: ${(props) => props.theme.palette.error};
  background: ${(props) => `${props.theme.palette.error}10`};
  border-radius: 8px;
  margin-bottom: 10px;
  font-size: 0.875rem;
  transition: all 300ms ease-in-out;
`;

const NewPage = () => {
  const { id } = useParams();

  const intl = useIntl();
  let navigate = useNavigate();

  const { token } = useAuth();

  const userData = decodeToken(token);

  const [errors, setErrors] = React.useState({
    name: {
      value: false,
      text: intl.formatMessage({ id: 'name must not be empty' }),
      validate: (value) => value === null || value === '', // returns true if there is an error and false if there is not
    },
    path: {
      value: false,
      text: intl.formatMessage({ id: 'path must not be empty' }),
      validate: (value) => value === null || value === '', // returns true if there is an error and false if there is not
    },
  });

  const [data, setData] = React.useState({
    name: '',
    description: '',
    path: null,
  });

  const dispatch = useDispatch();

  const { alertMessage } = bindActionCreators(actionCreators, dispatch);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const back = () => {
    navigate(-1);
  };

  const validateForm = () => {
    let foundError = false;
    const newErrors = {};
    Object.keys(errors).forEach((err) => {
      if (!foundError) {
        foundError = errors[err].validate(data[err]);
      }
      const val = errors[err].validate(data[err]);
      newErrors[err] = {
        ...errors[err],
        value: val,
      };
      setErrors({
        ...errors,
        ...newErrors,
      });
    });
    return !foundError;
  };

  const submit = () => {
    if (validateForm()) {
      const formData = new FormData();
      formData.append('name', data.name);
      formData.append('description', data.description);
      formData.append('path', data.path?.value ? data.path?.value : data.path);
      formData.append('user', userData.id);
      if (id) {
        axios
          .put(`${API_BACKEND}/pages/${id}`, formData, {})
          .then((res) => {
            alertMessage(intl.formatMessage({ id: 'page edited' }), alertConstants.INFO);
            navigate(-1);
          })
          .catch((error) => {
            alertMessage(error.response?.data?.msg, alertConstants.ERROR);
          });
      } else {
        axios
          .post(`${API_BACKEND}/pages`, formData, {})
          .then((res) => {
            alertMessage(intl.formatMessage({ id: 'page created' }), alertConstants.INFO);
            navigate(-1);
          })
          .catch((error) => {
            alertMessage(error.response?.data?.msg, alertConstants.ERROR);
          });
      }
    }
  };

  const getData = () => {
    axios
      .get(`${API_BACKEND}/pages/${id}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        alertMessage(error.response?.data?.msg, alertConstants.ERROR);
      });
  };

  const handleChangeAutocomplete = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  React.useEffect(() => {
    if (id) {
      getData();
    } else {
      setData({
        name: '',
        description: '',
        path: null,
      });
    }
  }, []);

  return (
    <Root>
      <Header>
        <Title>{id ? translate('edit page') : translate('new page')}</Title>
        <Actions>
          <ActionButton onClick={submit}>{translate('apply')}</ActionButton>
          <ActionButton onClick={back}>{translate('cancel')}</ActionButton>
        </Actions>
      </Header>
      {data && (
        <Container>
          <InputData>
            <StyledTextfield
              id="name"
              name="name"
              onChange={handleChange}
              value={data.name}
              label={intl.formatMessage({ id: 'name' })}
              variant="outlined"
              width="100%"
              margin="normal"
              size="small"
              required
            />
            <InputError show={errors.name.value}>{errors.name.text}</InputError>
          </InputData>
          <InputData>
            <StyledTextfield
              id="description"
              name="description"
              onChange={handleChange}
              value={data.description}
              label={intl.formatMessage({ id: 'description' })}
              variant="outlined"
              width="100%"
              margin="normal"
              size="small"
            />
          </InputData>
          <InputData>
            <SelectInput
              dataurl={`${API_BACKEND}/pages`}
              value={data.path}
              fieldlabel="fullpath"
              fieldvalue="fullpath"
              label="path"
              name="path"
              onChange={handleChangeAutocomplete}
              width="100%"
              margin="normal"
              size="small"
              required
            />
            <InputError show={errors.path.value}>{errors.path.text}</InputError>
          </InputData>
        </Container>
      )}
    </Root>
  );
};

export default NewPage;
