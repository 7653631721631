import React from 'react';
import styled from 'styled-components';
import translate from '../../providers/i18n/translate';
import { ThemeContext } from '../../providers/theme';
import { useIntl } from 'react-intl';
import { TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import SelectInput from '../inputs/SelectInput';
import { API_BACKEND, API_USERMANAGER } from '../../config';
import axios from 'axios';
import CodeEditor from '../textEditors/codeEditor';
import { decodeToken } from '../../hooks/jwt';
import { useAuth } from '../../providers/authentication';

/* Styled components */
const Root = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 600px;
  height: auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
`;

const Title = styled.h2`
  width: 100%;
  border-bottom: 1px solid ${(props) => (props.borderColor ? props.borderColor : '#000')};
  text-transform: uppercase;
  color: ${(props) => (props.color ? props.color : '#000')};
  margin: 0 0 10px 0;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 5px;
  background-color: ${(props) => (props.bg ? props.bg : '#7A7A7A')};
  color: ${(props) => (props.color ? props.color : '#FFF')};
  border: none;
  outline: none;
  cursor: pointer;
  margin: 10px 0;
  transition: all 300ms ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`;

const ContentEditor = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
`;

const NewConfig = ({ configData, handleClose }) => {
  const theme = React.useContext(ThemeContext);

  const intl = useIntl();

  const { token } = useAuth();

  const userData = decodeToken(token);

  const [editorLoaded, setEditorLoaded] = React.useState(false);

  const [newConfig, setNewConfig] = React.useState(
    configData
      ? {
          name: configData.name,
          value: `<pre><code>${configData.name.value === 'analytics' ? JSON.stringify(JSON.parse(configData.value), null, '\t') : configData.value}</code></pre>`,
        }
      : {
          name: null,
          value: '<pre><code>{\n\t\n}</code></pre>',
        }
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewConfig({
      ...newConfig,
      [name]: value,
    });
  };

  const handleEditor = (e, dataeditor) => {
    setNewConfig({
      ...newConfig,
      value: dataeditor,
    });
  };

  const valueParser = (value) => {
    var el = document.createElement('html');
    el.innerHTML = value;
    if ((configData ? configData.name : newConfig?.name?.value) === 'analytics') {
      try {
        JSON.parse(el.getElementsByTagName('code')[0].innerHTML);
      } catch (e) {
        return false;
      }
      return JSON.stringify(JSON.parse(el.getElementsByTagName('code')[0].innerHTML));
    }
    return el.getElementsByTagName('code')[0].innerHTML;
  };

  const handleChangeAutocomplete = (name, value) => {
    setNewConfig({
      ...newConfig,
      [name]: value,
    });
  };

  const handleAction = (e) => {
    e.preventDefault();

    if (configData) {
      if (valueParser(newConfig.value)) {
        const url = `${API_BACKEND}/configuration/${configData.id}`;
        const formData = new FormData();
        formData.append('value', valueParser(newConfig.value));
        formData.append('user', userData.id);
        axios
          .put(url, formData, {})
          .then((response) => {
            handleClose();
            /* alert(intl.formatMessage({ id: 'successful editing' })); */
          })
          .catch((error) => {
            console.log(error);
            alert(error.response.data.msg);
          });
      } else {
        alert(intl.formatMessage({ id: 'check syntax' }));
      }
    } else {
      if (valueParser(newConfig.value)) {
        const url = `${API_BACKEND}/configuration`;
        const formData = new FormData();
        formData.append('name', newConfig.name?.value ?? null);
        formData.append('value', valueParser(newConfig.value));
        formData.append('user', userData.id);
        axios
          .post(url, formData, {})
          .then((response) => {
            handleClose();
          })
          .catch((err) => {
            console.log(err);
            alert(err.response.data.msg);
          });
      } else {
        alert(intl.formatMessage({ id: 'check syntax' }));
      }
    }
  };

  React.useEffect(() => {
    setEditorLoaded(true);
  }, [newConfig]);

  return (
    <Root>
      <Title>{configData ? translate('edit config') : translate('new config')}</Title>
      <TextField
        style={{ display: 'none' }}
        disabled
        id="name"
        name="name"
        onChange={handleChange}
        value={newConfig.name ?? ''}
        label={intl.formatMessage({ id: 'name' })}
        variant="outlined"
        fullWidth
        margin="dense"
        size="small"
        required
      />
      <SelectInput
        disabled={configData ? true : false}
        value={configData ? { label: configData.name, value: configData.name } : newConfig.name}
        fieldlabel="value"
        fieldvalue="value"
        dataurl={`${API_BACKEND}/ConfigurationType`}
        label={intl.formatMessage({ id: 'config type' })}
        name="name"
        onChange={handleChangeAutocomplete}
        width="100%"
        margin="dense"
        size="small"
        required
      />
      <ContentEditor>
        <CodeEditor
          name="editorData"
          value={newConfig.value}
          onChange={handleEditor}
          editorLoaded={editorLoaded}
          maxContentHeight="200px"
          minContentHeight="200px"
          placeholder={intl.formatMessage({ id: 'insert subtitle here...' })}
        />
      </ContentEditor>
      <Button bg={theme.palette.accent} color={theme.palette.base} onClick={handleAction}>
        <CheckIcon />
      </Button>
    </Root>
  );
};

export default NewConfig;
