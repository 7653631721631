export function hexToRgb(hex) {
  hex = hex.replace(/^#/, '');

  let bigint = parseInt(hex, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;

  return { r, g, b };
}

export function hexToHsv(hex) {
  const { r, g, b } = hexToRgb(hex);

  const rAbs = r / 255;
  const gAbs = g / 255;
  const bAbs = b / 255;

  const max = Math.max(rAbs, gAbs, bAbs);
  const min = Math.min(rAbs, gAbs, bAbs);
  const delta = max - min;

  let h = 0;
  let s = 0;
  let v = max;

  if (delta !== 0) {
    s = delta / max;

    switch (max) {
      case rAbs:
        h = ((gAbs - bAbs) / delta) % 6;
        break;
      case gAbs:
        h = (bAbs - rAbs) / delta + 2;
        break;
      case bAbs:
        h = (rAbs - gAbs) / delta + 4;
        break;
      default:
        break;
    }

    h = Math.round(h * 60);
    if (h < 0) h += 360;
  }

  return { h, s: +(s * 100).toFixed(1), v: +(v * 100).toFixed(1) };
}

export function hexToColorPickerObject(hex) {
  return {
    hex: hex,
    rgb: hexToRgb(hex),
    hsv: hexToHsv(hex),
  };
}
