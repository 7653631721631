import axios from 'axios';
import { API_BACKEND } from '../../config';
import { alertConstants } from '../../store/reducers/messageBar/alertConstants';

export class CourseClient {
  async addCourse(course) {
    await axios.post(`${API_BACKEND}/course`, course.createFormData(), {});
  }

  async updateCourse(idCourse, course) {
    await axios.put(`${API_BACKEND}/course/${idCourse}`, course.createFormData(), {});
  }
}
