import React from 'react';
import { TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import FloorSection from './FloorSection';
import styled from 'styled-components';
import translate from '../../../providers/i18n/translate';

const Root = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 0 0 50px 0;
`;

const StyledTextfield = styled(TextField)`
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : 'inherit')};
  }
`;

const AddSection = styled.button`
  height: auto;
  width: auto;
  background: #a7a7a7;
  color: #fff;
  padding: 10px;
  font-weight: 600;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  margin: 5px 0;
`;

const FloorsDetails = ({ data, setData }) => {
  const intl = useIntl();

  const onChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const addSection = () => {
    const newsections = [...data.sections];
    newsections.push({
      title: 'Nueva sección',
      thead: [],
      tbody: [],
    });
    setData({
      ...data,
      sections: [...newsections],
    });
  };

  const deleteSection = (index) => {
    const newSections = [...data.sections];
    newSections.splice(index, 1);
    setData({
      ...data,
      sections: [...newSections],
    });
  };

  const addHeadTitle = (index) => {
    const newSections = [...data.sections];
    newSections[index].thead.push('Nueva columna');
    newSections[index].tbody.forEach((element) => {
      element.push('Insertar información...');
    });
    setData({
      ...data,
      sections: [...newSections],
    });
  };

  const deleteHeadTitle = (sectionindex, columnindex) => {
    const newSections = [...data.sections];
    newSections[sectionindex].thead.splice(columnindex, 1);
    newSections[sectionindex].tbody.forEach((element) => {
      element.splice(columnindex, 1);
    });
    setData({
      ...data,
      sections: [...newSections],
    });
  };

  const addRow = (sectionindex) => {
    const newSections = [...data.sections];
    const newRow = [];
    data.sections[sectionindex].thead.forEach((element) => {
      newRow.push('Insertar información');
    });
    newSections[sectionindex].tbody.push(newRow);
    setData({
      ...data,
      sections: [...newSections],
    });
  };

  const deleteRow = (sectionindex, rowindex) => {
    const newSections = [...data.sections];
    newSections[sectionindex].tbody.splice(rowindex, 1);
    setData({
      ...data,
      sections: [...newSections],
    });
  };

  const changeHeadTitle = (sectionindex, columnindex, input) => {
    const newSections = [...data.sections];
    newSections[sectionindex].thead[columnindex] = input;
    setData({
      ...data,
      sections: [...newSections],
    });
  };

  const changeCell = (sectionindex, rowindex, columnindex, input) => {
    const newSections = [...data.sections];
    newSections[sectionindex].tbody[rowindex][columnindex] = input;
    setData({
      ...data,
      sections: [...newSections],
    });
  };

  return (
    <Root>
      <StyledTextfield
        id="title-floors-details"
        name="title"
        onChange={onChange}
        value={data?.title}
        label={intl.formatMessage({ id: 'title' })}
        variant="outlined"
        width="100%"
        margin="normal"
        size="small"
      />
      {data?.sections?.map((section, index) => (
        <FloorSection
          id={`section_${index}`}
          sectionindex={index}
          data={section}
          deleteSection={deleteSection}
          addHeadTitle={addHeadTitle}
          deleteHeadTitle={deleteHeadTitle}
          addRow={addRow}
          deleteRow={deleteRow}
          changeHeadTitle={changeHeadTitle}
          changeCell={changeCell}
        />
      ))}
      <AddSection onClick={addSection}>{translate('add section')}</AddSection>
    </Root>
  );
};

export default FloorsDetails;
