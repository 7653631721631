import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import styled from 'styled-components';

const StyledFormGroup = styled(FormGroup)`
  width: ${(props) => (props.width ? props.width : '100%')};
`;

const CheckInput = ({ checked, onChange, label, name, width }) => {
  const handleChange = (event) => {
    onChange(name, event.target.checked);
  };

  return (
    <StyledFormGroup width={width}>
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={handleChange} />}
        label={label}
      />
    </StyledFormGroup>
  );
};

export default CheckInput;
