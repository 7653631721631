import React from 'react';
import axios from 'axios';
import { API_USERMANAGER } from '../../config';

const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
  const [token, setToken] = React.useState(JSON.parse(localStorage.getItem('token')));

  // data = {userName, password}
  const handleLogin = (data, cb) => {
    axios
      .post(`${API_USERMANAGER}/api/users/login`, data, { withCredentials: true })
      .then((result) => {
        setToken(result.data.token);
        localStorage.setItem('token', JSON.stringify(result.data.token));
        cb();
      })
      .catch((err) => {
        alert(err.response.data.msg);
        console.log(err.response);
      });
  };

  const handleLogout = (cb) => {
    axios
      .post(`${API_USERMANAGER}/api/users/logout`, {}, { withCredentials: true })
      .then((result) => {
        setToken(null);
        localStorage.removeItem('token');
        if (cb) {
          cb();
        }
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const value = {
    token,
    onLogin: handleLogin,
    onLogout: handleLogout,
  };

  React.useEffect(() => {
    if (token) {
      axios
        .get(`${API_USERMANAGER}/api/users/verifytoken`, { withCredentials: true })
        .catch((err) => {
          console.log(err.response);
          handleLogout();
        });
    } else {
      handleLogout();
    }
  }, [token]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return React.useContext(AuthContext);
};

export default AuthProvider;
