import es from './es-AR';
import en from './en-US';

// Combina los objetos en uno nuevo y lo asigna a una variable
const combinedLocales = {
  ...es,
  ...en,
};

// Exporta la variable combinada como el valor predeterminado del módulo
export default combinedLocales;
