import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { I18nProvider, LOCALES } from './providers/i18n';
import { ThemeProvider, THEMES } from './providers/theme';
import AuthProvider from './providers/authentication';
import GlobalStyle from './globalStyles';
import Protected from './providers/authentication/protected';
// LAYOUTS
import AdminLayout from './components/layouts/AdminLayout';
// PAGES
import NotFoundPage from './pages/NotFoundPage';
import AdminPage from './pages/AdminPage';
import Login from './pages/Login';
import UsersPage from './pages/UsersPage';
// COMPONENTS
import LodgingsPage from './pages/lodgingsPage';
import NewLodging from './components/lodging/newLodging';
import CoursesPage from './pages/coursesPage';
import PostsPage from './pages/postsPage';
import NewPost from './components/post/newPost';
import SectionsPage from './pages/SectionsPage';
import PagesPage from './pages/PagesPage';
import NewPage from './components/page/newPage';
import ConfigSection from './components/section/configSection';
import ProjectsPage from './pages/projectsPage';
import NewProject from './components/projects/newProject';
import ConfigPage from './pages/ConfigPage';
import MessageBar from './components/messageBar';
import LayoutsPage from './pages/LayoutsPage';
import LayoutConfig from './components/layoutConfig/layoutConfig';
import EditCourse from './components/courses/EditCourse';
import NewCourse from './components/courses/NewCourse';

function App() {
  return (
    <React.Fragment>
      <GlobalStyle />
      <ThemeProvider theme={THEMES.default}>
        <I18nProvider locale={LOCALES.SPANISH}>
          <AuthProvider>
            <MessageBar />
            <Routes>
              <Route path="/" element={<Navigate to="/login" />} />
              <Route path="/login" element={<Login />} />
              <Route
                exact
                path="/admin"
                element={
                  <Protected>
                    <AdminLayout />
                  </Protected>
                }
              >
                <Route path="/admin/dashboard" element={<AdminPage />} />
                <Route path="/admin/configuration" element={<ConfigPage />} />
                <Route path="/admin/lodgings" element={<LodgingsPage />} />
                <Route path="/admin/newlodging" element={<NewLodging />} />
                <Route path="/admin/lodgings/:id" element={<NewLodging />} />
                <Route path="/admin/projects" element={<ProjectsPage />} />
                <Route path="/admin/newproject" element={<NewProject />} />
                <Route path="/admin/projects/:id" element={<NewProject />} />
                <Route path="/admin/posts" element={<PostsPage />} />
                <Route path="/admin/newpost" element={<NewPost />} />
                <Route path="/admin/posts/:id" element={<NewPost />} />
                <Route path="/admin/pages" element={<PagesPage />} />
                <Route path="/admin/layouts" element={<LayoutsPage />} />
                <Route path="/admin/layouts/:id" element={<LayoutConfig />} />
                <Route path="/admin/newpage" element={<NewPage />} />
                <Route path="/admin/pages/:id" element={<NewPage />} />
                <Route path="/admin/sections" element={<SectionsPage />} />
                <Route path="/admin/sections/:id" element={<ConfigSection />} />
                <Route path="/admin/users" element={<UsersPage />} />
                <Route path="/admin/courses" element={<CoursesPage />} />
                <Route path="/admin/courses/:id" element={<EditCourse />} />
                <Route path="/admin/newcourse" element={<NewCourse />} />
              </Route>
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </AuthProvider>
        </I18nProvider>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;
