import React, { useId } from 'react';
import styled from 'styled-components';
import { ThemeContext } from '../../providers/theme';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import translate from '../../providers/i18n/translate';

/* Styled components */
const FileUpload = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  min-height: 150px;
  align-items: center;
  justify-content: space-evenly;
  font-family: museo-sans, sans-serif;
`;

const ContentImage = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 250px;
  background-color: #ececec;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  margin: 10px 0;
  img {
    object-fit: cover;
    object-position: center;
  }
  svg {
    font-size: 3rem;
    margin-bottom: 0.5rem;
  }
  span {
    font-size: 1rem;
  }
`;

const StyledButton = styled.label`
  font-weight: 300;
  font-style: normal;
  color: ${(props) => (props.color ? props.color : '#4a4a4a')};
  cursor: pointer;
  text-decoration: underline;
  font-size: 1rem;
  transition: all 300ms ease-in-out;
  &:hover {
    color: ${(props) => (props.hoverColor ? props.hoverColor : '#3a3a3a')};
  }
`;

const CancelButton = styled.button`
  font-weight: 300;
  font-style: normal;
  color: ${(props) => (props.color ? props.color : '#4a4a4a')};
  cursor: pointer;
  text-decoration: underline;
  margin: 0;
  border: none;
  background: none;
  font-size: 1rem;
  transition: all 300ms ease-in-out;
  &:hover {
    color: ${(props) => (props.hoverColor ? props.hoverColor : '#3a3a3a')};
  }
`;

const InputImage = (props) => {
  const id = useId();
  const theme = React.useContext(ThemeContext);

  const [image, setImage] = React.useState(props.file ? props.file : null);
  const [preview, setPreview] = React.useState(null);

  const onChange = (e) => {
    const { files } = e.target;
    props.onChange(files);
    setImage(files[0]);
  };

  const onReset = (e) => {
    e.preventDefault();
    props.onReset();
    setPreview(null);
    setImage(null);
  };

  React.useEffect(() => {
    if (image && typeof image !== 'string') {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(image);
    } else {
      if (typeof props.file == 'string') {
        setImage(props.file);
      } else {
        setPreview(null);
      }
    }
  }, [image]);

  React.useEffect(
    () => {
      if (!image && props.file) {
        setImage(props.file);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.file]
  );

  return (
    <FileUpload>
      {image ? (
        <ContentImage
          htmlFor={props.id ? `contained-button-file-${props.id}` : `contained-button-file-${id}`}
        >
          {preview ? (
            <img width="100%" height="100%" src={preview} alt="photo" />
          ) : (
            <img width="100%" height="100%" src={image} alt="photo" />
          )}
        </ContentImage>
      ) : (
        <ContentImage
          htmlFor={props.id ? `contained-button-file-${props.id}` : 'contained-button-file'}
        >
          <AddAPhotoIcon />
          <span>{translate('upload')}</span>
          <span>{translate('photo')}</span>
        </ContentImage>
      )}
      <input
        id={props.id ? `contained-button-file-${props.id}` : 'contained-button-file'}
        name="photo"
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={onChange}
        required={props.required ? props.required : false}
      />
      <StyledButton
        color={theme.palette.principal}
        hoverColor={theme.palette.accent}
        htmlFor={props.id ? `contained-button-file-${props.id}` : 'contained-button-file'}
      >
        {props.file ? translate('change') : translate('choose file')}
      </StyledButton>
      {props.file && (
        <CancelButton
          color={theme.palette.principal}
          hoverColor={theme.palette.accent}
          onClick={onReset}
        >
          {translate('remove')}
        </CancelButton>
      )}
    </FileUpload>
  );
};

export default InputImage;
