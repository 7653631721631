import React from 'react';
import styled from 'styled-components';
import translate from '../../providers/i18n/translate';
import { ThemeContext } from '../../providers/theme';
import { useIntl } from 'react-intl';
import CheckIcon from '@mui/icons-material/Check';
import { API_USERMANAGER } from '../../config';
import axios from 'axios';
import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

/* Styled components */
const Root = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 450px;
  height: auto;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
`;

const Title = styled.h2`
  width: 100%;
  border-bottom: 1px solid ${(props) => (props.borderColor ? props.borderColor : '#000')};
  text-transform: uppercase;
  color: ${(props) => (props.color ? props.color : '#000')};
  margin: 0 0 10px 0;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 5px;
  background-color: ${(props) => (props.bg ? props.bg : '#7A7A7A')};
  color: ${(props) => (props.color ? props.color : '#FFF')};
  border: none;
  outline: none;
  cursor: pointer;
  margin: 10px 0;
  transition: all 300ms ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`;

const ChangeUserPassword = ({ userData, handleClose }) => {
  const theme = React.useContext(ThemeContext);

  const intl = useIntl();

  const [data, setData] = React.useState({
    oldPassword: '',
    newPassword: '',
    repassword: '',
  });

  const [showPassword, setShowPassword] = React.useState({
    oldPassword: false,
    newPassword: false,
    repassword: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleClickShowPassword = (e) => {
    e.preventDefault();
    const { id } = e.currentTarget.dataset;
    setShowPassword({
      ...showPassword,
      [id]: !showPassword[id],
    });
  };

  const handleAction = (e) => {
    e.preventDefault();
    if (data.repassword === data.newPassword) {
      const url = `${API_USERMANAGER}/api/users/user/password?id=${userData.id}`;
      axios
        .put(url, data, {
          withCredentials: true,
        })
        .then((response) => {
          handleClose();
        })
        .catch((err) => {
          console.log(err.response?.data);
          alert(err.response?.data?.msg);
        });
    } else {
      alert(intl.formatMessage({ id: 'check password' }));
    }
  };

  return (
    <Root>
      <Title>{translate('change password')}</Title>
      <FormControl variant="standard" fullWidth>
        <InputLabel htmlFor="standard-adornment-oldPassword">{translate('oldpassword')}</InputLabel>
        <Input
          id="standard-adornment-oldPassword"
          name="oldPassword"
          type={showPassword.oldPassword ? 'text' : 'password'}
          value={data.oldPassword}
          onChange={handleChange}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle oldPassword visibility"
                data-id="oldPassword"
                onClick={handleClickShowPassword}
              >
                {showPassword.oldPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <FormControl variant="standard" fullWidth>
        <InputLabel htmlFor="standard-adornment-newPassword">{translate('newpassword')}</InputLabel>
        <Input
          id="standard-adornment-newPassword"
          name="newPassword"
          type={showPassword.newPassword ? 'text' : 'password'}
          value={data.newPassword}
          onChange={handleChange}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle newPassword visibility"
                data-id="newPassword"
                onClick={handleClickShowPassword}
              >
                {showPassword.newPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <FormControl variant="standard" fullWidth>
        <InputLabel htmlFor="standard-adornment-repassword">{translate('repassword')}</InputLabel>
        <Input
          id="standard-adornment-repassword"
          name="repassword"
          type={showPassword.repassword ? 'text' : 'password'}
          value={data.repassword}
          onChange={handleChange}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle repassword visibility"
                data-id="repassword"
                onClick={handleClickShowPassword}
              >
                {showPassword.repassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <Button bg={theme.palette.accent} color={theme.palette.base} onClick={handleAction}>
        <CheckIcon />
      </Button>
    </Root>
  );
};

export default ChangeUserPassword;
