import React from 'react';
import styled from 'styled-components';
import { ThemeContext } from '../../../providers/theme';
import SearchIcon from '@mui/icons-material/Search';
import { useIntl } from 'react-intl';

/* Styled components */

const Input = styled.input`
  line-height: 1.25rem;
  margin: 8px 0 8px 0;
  width: 100%;
  padding: 0 5px 0 10px;
  border: none;
  outline: none;
  background-color: transparent;
`;

const StyledForm = styled.form`
  position: relative;
  display: grid;
  grid-template-columns: auto 40px;
  height: 40px;
  width: 150px;
  border: 1px solid grey;
  border-radius: 20px;
  transition: all 300ms ease-in-out;
  &:focus-within {
    border-color: ${(props) => (props.focusColor ? props.focusColor : '#707070')};
  }
  @media (min-width: 1024px) {
    width: 200px;
  }
`;

const Icon = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  svg {
    color: ${(props) => (props.color ? props.color : '#000')};
  }
  &:hover {
    background-color: rgba(112, 112, 112, 0.1);
  }
  background-color: transparent;
`;

const SearchInput = ({ search, onChange, onSearch }) => {
  const theme = React.useContext(ThemeContext);

  const intl = useIntl();

  const handleChange = (e) => {
    e.preventDefault();
    const { value } = e.target;
    onChange(value);
  };

  const submit = (e) => {
    e.preventDefault();
    onSearch();
  };

  return (
    <StyledForm focusColor={theme.palette.principal} onSubmit={submit}>
      <Input
        id="search-input"
        name="search"
        type="text"
        value={search}
        onChange={handleChange}
        placeholder={intl.formatMessage({ id: 'search' })}
      />
      <Icon color={theme.palette.principal}>
        <SearchIcon />
      </Icon>
    </StyledForm>
  );
};

export default SearchInput;
