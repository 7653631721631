import { Assertion } from '../../creationAssertion/Assertion';
import { AssertionsRunner } from '../../creationAssertion/AssertionsRunner';
import Text from '../generic/Text/Text';
import ButtonLink from '../generic/Button/ButtonLink';
import Section from './Section';

export class Sponsor extends Section {
  static ERROR_MESSAGE_REQUIRED_FIELD = 'error required field';

  title;
  button;
  images;
  userId;
  assertionsRun;

  createAssertText(hasText, textFormatter) {
    if (hasText) {
      this.assertionsRun.push(
        Assertion.for(
          textFormatter.text,
          Text.ERROR_INVALID_TITLE_TEXT,
          () =>
            !(
              textFormatter.text === undefined ||
              textFormatter.text === null ||
              textFormatter.text.trim().length === 0
            ),
          Sponsor.ERROR_MESSAGE_REQUIRED_FIELD
        )
      );
      this.assertionsRun.push(
        Assertion.for(
          textFormatter.color,
          Text.ERROR_INVALID_TITLE_COLOR,
          () =>
            !(
              textFormatter.color === undefined ||
              textFormatter.color === null ||
              textFormatter.color.trim().length === 0
            ),
          Sponsor.ERROR_MESSAGE_REQUIRED_FIELD
        )
      );
      this.assertionsRun.push(
        Assertion.for(
          textFormatter.alignment,
          Text.ERROR_INVALID_TITLE_ALIGNMENT,
          () =>
            !(
              textFormatter.alignment === undefined ||
              textFormatter.alignment === null ||
              textFormatter.alignment.label.trim().length === 0
            ),
          Sponsor.ERROR_MESSAGE_REQUIRED_FIELD
        )
      );
    }
  }

  createAssertButton(hasButton, buttonLink) {
    if (hasButton) {
      this.assertionsRun.push(
        Assertion.for(
          buttonLink.text,
          ButtonLink.ERROR_INVALID_BUTTON_TEXT,
          () => buttonLink.hasText(),
          Sponsor.ERROR_MESSAGE_REQUIRED_FIELD
        )
      );
      this.assertionsRun.push(
        Assertion.for(
          buttonLink.link,
          ButtonLink.ERROR_INVALID_LINK_BUTTON,
          () => buttonLink.hasLink(),
          Sponsor.ERROR_MESSAGE_REQUIRED_FIELD
        )
      );
      this.assertionsRun.push(
        Assertion.for(
          buttonLink.type,
          ButtonLink.ERROR_INVALID_BUTTON_TYPE,
          () => buttonLink.hasType(),
          Sponsor.ERROR_MESSAGE_REQUIRED_FIELD
        )
      );
    }
  }

  constructor(hasText, hasButton, textFormatter, buttonLink, userId, images) {
    super();

    this.assertionsRun = [];

    this.createAssertText(hasText, textFormatter);
    this.createAssertButton(hasButton, buttonLink);

    AssertionsRunner.assertAll(this.assertionsRun);

    this.images = images.map((image) => {
      return {
        id: image.id,
        img: image.url,
        imgDesktop: image.url,
        imgTablet: image.url,
        imgMobile: image.url,
        alt: image.alt,
        order: image.order,
        data: image.data,
      };
    });

    this.title = {
      hasText: hasText,
      text: textFormatter.text,
      alignment: textFormatter.alignment.value,
      color: textFormatter.color,
    };

    this.button = {
      hasButton: hasButton,
      text: buttonLink.text,
      link: buttonLink.link,
      newTabLink: buttonLink.newTabLink,
      type: buttonLink.type.value,
    };

    this.userId = userId;
  }

  formData() {
    const formData = new FormData();
    const data = {
      images: this.images,
      title: this.title,
      button: this.button,
    };
    formData.append('data', JSON.stringify(data));
    formData.append('user', this.userId);

    return formData;
  }
}
