import { Modal } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { ThemeContext } from '../../providers/theme';
import NewItem from './newItem';
import { truncateText } from '../../providers/commonFunctions';

const Root = styled.div`
  position: relative;
  width: 200px;
  height: 130px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  display: flex;
  min-height: 130px;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: absolute;
  top: 0;
  left: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  background: ${(props) => props.theme.palette.base};
  border-radius: 0 8px 8px 0;
  overflow: hidden;
`;

const Button = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  color: ${(props) => props.theme.palette.principal};
  transition: all 300ms ease-in-out;
  ${(props) => (props.forceColumn ? '' : 'transform: rotate(-90deg);')}
  &:hover {
    background: ${(props) => `${props.theme.palette.details}AA`};
    color: ${(props) => props.theme.palette.base};
  }
`;

const ContentButton = styled(Button)`
  transform: rotate(0);
`;

const DataContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;
const Name = styled.div`
  position: relative;
  width: auto;
  height: auto;
  font-size: 1rem;
  line-height: 1;
`;

const TextArea = styled.div`
  position: relative;
  width: auto;
  height: auto;
  line-height: 1;
  font-size: 1rem;
`;

const Item = ({
  data,
  first,
  last,
  handleDelete,
  handleUp,
  handleDown,
  forceColumn,
  editItem,
  contentMediaType,
}) => {
  const theme = React.useContext(ThemeContext);

  const [openContent, setOpenContent] = React.useState(
    !data.data && contentMediaType == 'cardsBand' ? true : false
  );

  const onDelete = (e) => {
    e.preventDefault();
    handleDelete(data.id);
  };

  const onUp = (e) => {
    e.preventDefault();
    handleUp(data.id);
  };

  const onDown = (e) => {
    e.preventDefault();
    handleDown(data.id);
  };

  const onOpenContent = (e) => {
    e.preventDefault();
    setOpenContent(true);
  };

  const onCloseContent = (event, reason) => {
    switch (contentMediaType) {
      case 'cardsBand':
        reason !== 'backdropClick' && setOpenContent(false);
        break;
      default:
        setOpenContent(false);
    }
  };

  React.useEffect(() => {}, [data]);

  return (
    <Root>
      <Actions>
        <Button onClick={onDelete} forceColumn={true}>
          {theme.icons.close}
        </Button>
        {!first && (
          <Button onClick={onUp} forceColumn={forceColumn}>
            {theme.icons.arrowUp}
          </Button>
        )}
        {!last && (
          <Button onClick={onDown} forceColumn={forceColumn}>
            {theme.icons.arrowDown}
          </Button>
        )}
        <ContentButton onClick={onOpenContent} forceColumn={forceColumn}>
          {theme.icons.text}
        </ContentButton>
      </Actions>
      <DataContainer>
        <Name>{data?.title ?? ''}</Name>
        <TextArea>{truncateText(data?.content, 100) ?? ''}</TextArea>
      </DataContainer>
      <Modal open={openContent} onClose={onCloseContent}>
        <NewItem editData={data} handleEdit={editItem} onClose={onCloseContent} />
      </Modal>
    </Root>
  );
};

export default Item;
