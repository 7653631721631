import React from 'react';
import styled from 'styled-components';
import translate from '../../../providers/i18n/translate';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useIntl } from 'react-intl';

/* Styled components */
const Root = styled.div`
  display: ${(props) => (props.show ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  svg {
    cursor: pointer;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
  @media (min-width: 400px) {
    flex-direction: row;
  }
`;

const PageNumber = styled.input`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 45px;
  padding: 5px 10px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  margin: 0;
  outline: none;
`;

const StyledSelect = styled.select`
  padding: 5px 0 5px 5px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  background-color: transparent;
  margin: 0 10px;
  outline: none;
`;

const Box = styled.div`
  display: ${(props) => (props.hiddenMobile ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  margin: 5px 0;
  @media (min-width: 400px) {
    display: flex;
    margin: 0;
  }
`;
const Divider = styled.span`
  margin: 10px;
  display: none;
  @media (min-width: 400px) {
    display: inherit;
  }
`;

const Pagination = ({
  currentPage,
  pages,
  show,
  pageOptions,
  previos,
  next,
  changePage,
  limit,
  changeLimit,
  refresh,
  total,
}) => {
  const pageRef = React.useRef();

  const intl = useIntl();

  const onChangeLimit = (e) => {
    e.preventDefault();
    const { value } = e.target;
    changeLimit(value);
  };

  const onChangePage = (e) => {
    e.preventDefault();
    const { value } = e.target;
    if (value <= pages) {
      changePage(value);
    }
  };

  const handlePage = (e) => {
    e.preventDefault();
    refresh();
  };

  const handlePageKeyUp = (e) => {
    e.preventDefault();
    const keycode = e.keyCode ? e.keyCode : e.which;
    if (keycode == '13') {
      pageRef.current.blur();
    }
  };

  return (
    <Root show={show}>
      <Box>
        {translate('page')}
        <NavigateBeforeIcon onClick={previos} />
        <PageNumber
          ref={pageRef}
          type="number"
          margin="none"
          size="small"
          value={currentPage}
          onChange={onChangePage}
          onBlur={handlePage}
          onKeyUp={handlePageKeyUp}
        />
        <NavigateNextIcon onClick={next} />
        {translate('of')}
        {` ${pages}`}
      </Box>
      <Divider>|</Divider>
      <Box hiddenMobile={true}>
        {translate('show')}
        <StyledSelect id="demo-simple-select" value={limit} onChange={onChangeLimit}>
          {pageOptions &&
            pageOptions.map((opt, index) => (
              <option key={`page_option_${index}`} value={opt}>
                {opt}
              </option>
            ))}
        </StyledSelect>
        {` `}
        {translate('records')}
        {total && <Divider>|</Divider>}

        {total && `${intl.formatMessage({ id: 'total' })}: ${total}`}
      </Box>
    </Root>
  );
};

export default Pagination;
