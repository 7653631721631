import React from 'react';
import styled from 'styled-components';
import { TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const Root = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const InputData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : 'inherit')};
  }
`;

const InputGroup = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
`;

const StyledTextfield = styled(TextField)`
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : 'inherit')};
  }
`;

const CardsBandCardsBottomArrows = ({ data, setData }) => {
  const intl = useIntl();

  const onChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChangeCheck = (e) => {
    const { name, checked } = e.target;
    setData({
      ...data,
      [name]: checked,
    });
  };

  return (
    <Root>
      <InputData>
        <StyledTextfield
          id="title"
          name="title"
          onChange={onChange}
          value={data?.title}
          label={intl.formatMessage({ id: 'title' })}
          variant="outlined"
          width="100%"
          margin="normal"
          size="small"
          required
        />
      </InputData>
      <FormControlLabel
        control={
          <Checkbox
            name="autoheight"
            checked={data?.autoheight}
            onChange={handleChangeCheck}
            inputProps={{ 'aria-label': 'controlled invert' }}
          />
        }
        label={intl.formatMessage({ id: 'autoheight' })}
      />
      <InputGroup>
        <StyledTextfield
          id="action"
          name="action"
          onChange={onChange}
          value={data?.action}
          label={intl.formatMessage({ id: 'text button' })}
          variant="outlined"
          width="50%"
          margin="normal"
          size="small"
        />
        <StyledTextfield
          id="link"
          name="link"
          onChange={onChange}
          value={data?.link}
          label={intl.formatMessage({ id: 'link' })}
          variant="outlined"
          width="50%"
          margin="normal"
          size="small"
        />
      </InputGroup>
    </Root>
  );
};

export default CardsBandCardsBottomArrows;
