import React from 'react';
import styled from 'styled-components';
import translate from '../../../providers/i18n/translate';

const Root = styled.tbody`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  justify-content: center;
`;

const Row = styled.tr`
  width: 100%;
  vertical-align: middle;
`;

const Column = styled.td`
  position: relative;
  width: 100%;
  display: flex;
`;

const AccordionBody = ({ rowsTable, accordion, actions }) => {
  const handleView = (e) => {
    e.preventDefault();
    const { index } = e.currentTarget.dataset;
    actions.view.onClick(rowsTable[index]);
  };

  const handleEdit = (e) => {
    e.preventDefault();
    const { index } = e.currentTarget.dataset;
    actions.edit.onClick(rowsTable[index]);
  };

  const handleDelete = (e) => {
    e.preventDefault();
    const { index } = e.currentTarget.dataset;
    actions.delete.onClick(rowsTable[index]);
  };

  return (
    <Root>
      {rowsTable &&
        rowsTable.map((data, index) => (
          <Row key={`accordion_${index}`}>
            <Column>{accordion(data)}</Column>
          </Row>
        ))}
    </Root>
  );
};

export default AccordionBody;
