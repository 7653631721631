import SelectInput from '../../inputs/SelectInput';
import React from 'react';
import { InputContainer } from '../../templatesSections/sponsorView';
import styled from 'styled-components';
import { TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import { ErrorMessage } from '../../courses/ErrorMessage';
import { observer } from 'mobx-react-lite';
import Text from './Text';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
`;

const Field = styled(TextField)`
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : 'inherit')};
  }
`;

const TextView = observer((props) => {
  const intl = useIntl();

  const horizontalAlignment = Text.ALIGNMENT.map((alignment) => {
    return {
      label: intl.formatMessage({ id: alignment.label }),
      value: alignment.value,
    };
  });

  const errorMessage = (errorMessage) => {
    if (props.error === undefined || props.error.hasError === false) return null;

    const errorDescriptions = props.error.fieldsError.map((assertion) =>
      assertion.getDescription()
    );
    return (
      <ErrorMessage
        condition={() =>
          props.error.hasError &&
          props.error.fieldsError.some((error) => error.isIdentifiedAs(errorMessage))
        }
        messageId={errorDescriptions[0]}
      />
    );
  };

  return (
    <Container>
      <InputContainer width="20%">
        <Field
          id="title"
          name="title"
          onChange={(event) => {
            props.textFormatter.text = event.target.value;
          }}
          value={props.textFormatter.text}
          label={'Titulo'}
          variant="outlined"
          width="100%"
          margin="normal"
          size="small"
          type="text"
        />
        {errorMessage(Text.ERROR_INVALID_TITLE_TEXT)}
      </InputContainer>
      <InputContainer width="20%">
        <Field
          id="titleColor"
          name="titcleColor"
          onChange={(event) => {
            props.textFormatter.color = event.target.value;
          }}
          value={props.textFormatter.color}
          label={'Color'}
          variant="outlined"
          width="100%"
          margin="normal"
          size="small"
          type="color"
        />
        {errorMessage(Text.ERROR_INVALID_TITLE_COLOR)}
      </InputContainer>
      <InputContainer width="20%">
        <SelectInput
          data={horizontalAlignment}
          onChange={(name, value) => {
            props.textFormatter.alignment = value;
          }}
          value={props.textFormatter.alignment}
          fieldlabel="label"
          fieldvalue="value"
          label={'Alineación Horizontal'}
          name="status"
          width="100%"
          margin="normal"
          size="small"
        />
        {errorMessage(Text.ERROR_INVALID_TITLE_ALIGNMENT)}
      </InputContainer>
    </Container>
  );
});

export default TextView;
