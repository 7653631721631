import { combineReducers } from 'redux';
import { alertMessage } from './messageBar/alertMessageReducer';
import { loading } from './loading/loadingReducer';

const reducers = combineReducers({
  alertMessage,
  loading,
});

export default reducers;
