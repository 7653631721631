import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../store/action-creators';
import { alertConstants } from '../../../store/reducers/messageBar/alertConstants';
import axios from 'axios';
import { API_BACKEND } from '../../../config';
import InputImage from '../../inputs/inputImage';
import { useIntl } from 'react-intl';
import { TextField } from '@mui/material';
import BasicEditor from '../../textEditors/basicEditor';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const Root = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 60% 40%;
  }
`;

const ContainerImgWithAlt = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  grid-column: 2/3;
  grid-row: 1/4;
`;

const ImageContainer = styled.div`
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const StyledTextfield = styled(TextField)`
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : 'inherit')};
  }
`;

const ContentEditor = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
`;

const InputError = styled.span`
  width: fit-content;
  height: ${(props) => (props.show ? 'auto' : 0)};
  overflow: hidden;
  color: ${(props) => props.theme.palette.error};
  padding: ${(props) => (props.show ? '8px 10px' : 0)};
  opacity: ${(props) => (props.show ? 1 : 0)};
  border: ${(props) => props.theme.palette.error};
  background: ${(props) => `${props.theme.palette.error}10`};
  border-radius: 8px;
  margin-bottom: 10px;
  font-size: 0.875rem;
  transition: all 300ms ease-in-out;
`;

const InputGroup = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
`;

const TwoColumnsLodging = ({ data, setData, editData }) => {
  const intl = useIntl();

  const [editorLoaded, setEditorLoaded] = React.useState(false);

  const dispatch = useDispatch();

  const { alertMessage } = bindActionCreators(actionCreators, dispatch);

  const onChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleEditor = (e, dataeditor) => {
    setData({
      ...data,
      text: dataeditor,
    });
  };

  const onChangeImage = (files) => {
    if (files.length > 0) {
      const formData = new FormData();
      formData.append('image', files[0]);
      axios
        .post(`${API_BACKEND}/sections/images`, formData, {})
        .then((res) => {
          setData({
            ...data,
            img: res.data.url,
          });
        })
        .catch((error) => {
          alertMessage(error.response?.data?.msg, alertConstants.ERROR);
        });
    }
  };

  const handleChangeCheck = (e) => {
    const { name, checked } = e.target;
    setData({
      ...data,
      [name]: checked,
    });
  };

  const onResetImage = () => {
    setData({
      ...data,
      img: null,
    });
  };

  React.useEffect(() => {
    setEditorLoaded(true);
  }, []);

  return (
    data && (
      <Root>
        <StyledTextfield
          id="title"
          name="title"
          onChange={onChange}
          value={data?.title}
          label={intl.formatMessage({ id: 'title' })}
          variant="outlined"
          width="100%"
          margin="normal"
          size="small"
        />
        <ContentEditor>
          <BasicEditor
            name="text"
            value={data.text}
            onChange={handleEditor}
            editorLoaded={editorLoaded}
            maxContentHeight="250px"
            minContentHeight="250px"
            placeholder={intl.formatMessage({ id: 'insert text here...' })}
          />
        </ContentEditor>
        <ContainerImgWithAlt>
          <ImageContainer>
            <InputImage
              id="twoColumnsLodging"
              file={data.img}
              onChange={onChangeImage}
              onReset={onResetImage}
            />
          </ImageContainer>
          <StyledTextfield
            id="alt"
            name="alt"
            onChange={onChange}
            value={data?.alt}
            label={intl.formatMessage({ id: 'alternative text' })}
            variant="outlined"
            width="70%"
            margin="normal"
            size="small"
          />
        </ContainerImgWithAlt>
        <InputGroup>
          <StyledTextfield
            id="action"
            name="action"
            onChange={onChange}
            value={data?.action}
            label={intl.formatMessage({ id: 'text button' })}
            variant="outlined"
            width="50%"
            margin="normal"
            size="small"
          />
          <StyledTextfield
            id="link"
            name="link"
            onChange={onChange}
            value={data?.link}
            label={intl.formatMessage({ id: 'link' })}
            variant="outlined"
            width="50%"
            margin="normal"
            size="small"
          />
        </InputGroup>
        <FormControlLabel
          control={
            <Checkbox
              name="invert"
              checked={data.invert}
              onChange={handleChangeCheck}
              inputProps={{ 'aria-label': 'controlled invert' }}
            />
          }
          label={intl.formatMessage({ id: 'invert' })}
        />
      </Root>
    )
  );
};

export default TwoColumnsLodging;
