import { Modal } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { ThemeContext } from '../../providers/theme';
import CardsBandContentMedia from './cardsBandContentMedia';
import HeroCarouselContentMedia from './heroCarouselContentMedia';
import HeroBannerBigTextContentMedia from './heroBannerBigTextContentMedia';
import HeroBannerInstitucionalContentMedia from './heroBannerInstitucionalContentMedia';
import IconsSectionContentMedia from './iconsSectionContentMedia';
import BlueprintsBandContentMedia from './blueprintsBandContentMedia';
import HoverCardsSectorsContentMedia from './hoverCardsSectorsContentMedia';
import TextButtonSliderContentMedia from './textButtonSliderContentMedia';
import StackedImagesSliderContentMedia from './stackedImagesSliderContentMedia';
import StackedImagesSliderV2ContentMedia from './stackedImagesSliderV2ContentMedia';
import ServicesCardsSectionContentMedia from './servicesCardsSectionContentMedia';
import TimeLineV2ContentMedia from './timeLineV2ContentMedia';
import translate from '../../providers/i18n/translate';

const Root = styled.div`
  position: relative;
  width: 300px;
  height: 150px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  display: flex;
  min-height: 150px;
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: absolute;
  top: 0;
  left: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  background: ${(props) => props.theme.palette.base};
  border-radius: 0 8px 8px 0;
  overflow: hidden;
`;

const Button = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  color: ${(props) => props.theme.palette.principal};
  transition: all 300ms ease-in-out;

  ${(props) => (props.forceColumn ? '' : 'transform: rotate(-90deg);')}
  &:hover {
    background: ${(props) => `${props.theme.palette.details}AA`};
    color: ${(props) => props.theme.palette.base};
  }
`;

const ContentButton = styled(Button)`
  transform: rotate(0);
`;

const Media = ({
  data,
  first,
  last,
  handleDelete,
  handleUp,
  handleDown,
  forceColumn,
  withContent,
  contentMediaType,
  handleMediaData,
}) => {
  const theme = React.useContext(ThemeContext);

  const [openContent, setOpenContent] = React.useState(
    !data.data && contentMediaType === 'cardsBand' ? true : false
  );

  const onDelete = (e) => {
    e.preventDefault();
    handleDelete(data.id);
  };

  const onUp = (e) => {
    e.preventDefault();
    handleUp(data.id);
  };

  const onDown = (e) => {
    e.preventDefault();
    handleDown(data.id);
  };

  const onOpenContent = (e) => {
    e.preventDefault();
    setOpenContent(true);
  };

  const onCloseContent = (event, reason) => {
    switch (contentMediaType) {
      case 'cardsBand':
        reason !== 'backdropClick' && setOpenContent(false);
        break;
      default:
        setOpenContent(false);
    }
  };

  //TODO: Mejorar el mecanismo para cargar el contenido de los media.
  const renderContent = (contentType) => {
    switch (contentType) {
      case 'heroCarousel':
        return (
          <HeroCarouselContentMedia
            id={data.id}
            editData={data.data}
            handleClose={onCloseContent}
            handleMediaData={handleMediaData}
          />
        );
      case 'cardsBand':
        return (
          <CardsBandContentMedia
            id={data.id}
            editData={data.data}
            handleDelete={onDelete}
            handleClose={onCloseContent}
            handleMediaData={handleMediaData}
          />
        );
      case 'blueprintsBand':
        return (
          <BlueprintsBandContentMedia
            id={data.id}
            editData={data.data}
            handleClose={onCloseContent}
            handleMediaData={handleMediaData}
          />
        );
      case 'heroBannerBigText':
        return (
          <HeroBannerBigTextContentMedia
            id={data.id}
            editData={data.data}
            handleMediaData={handleMediaData}
            handleClose={onCloseContent}
          />
        );
      case 'heroBannerInstitucional':
        return (
          <HeroBannerInstitucionalContentMedia
            id={data.id}
            editData={data.data}
            handleMediaData={handleMediaData}
            handleClose={onCloseContent}
          />
        );
      case 'iconsSection':
        return (
          <IconsSectionContentMedia
            id={data.id}
            editData={data.data}
            handleMediaData={handleMediaData}
            handleClose={onCloseContent}
          />
        );
      case 'hoverCardsSectors':
        return (
          <HoverCardsSectorsContentMedia
            id={data.id}
            editData={data.data}
            handleMediaData={handleMediaData}
            handleClose={onCloseContent}
          />
        );
      case 'textButtonSlider':
        return (
          <TextButtonSliderContentMedia
            id={data.id}
            editData={data.data}
            handleMediaData={handleMediaData}
            handleClose={onCloseContent}
          />
        );
      case 'stackedImagesSlider':
        return (
          <StackedImagesSliderContentMedia
            id={data.id}
            editData={data.data}
            handleMediaData={handleMediaData}
            handleClose={onCloseContent}
          />
        );
      case 'stackedImagesSliderV2':
        return (
          <StackedImagesSliderV2ContentMedia
            id={data.id}
            editData={data.data}
            handleMediaData={handleMediaData}
            handleClose={onCloseContent}
          />
        );
      case 'servicesCardsSection':
        return (
          <ServicesCardsSectionContentMedia
            id={data.id}
            editData={data.data}
            handleMediaData={handleMediaData}
            handleClose={onCloseContent}
          />
        );
      case 'servicesCardsSectionHome':
        return (
          <ServicesCardsSectionContentMedia
            id={data.id}
            editData={data.data}
            handleMediaData={handleMediaData}
            handleClose={onCloseContent}
          />
        );
      case 'timeLineV2':
        return (
          <TimeLineV2ContentMedia
            id={data.id}
            editData={data.data}
            handleMediaData={handleMediaData}
            handleClose={onCloseContent}
          />
        );
      default:
        return <span>{translate('contentType media not supported')}</span>;
    }
  };

  return (
    <Root>
      <Actions>
        <Button onClick={onDelete} forceColumn={true}>
          {theme.icons.close}
        </Button>
        {!first && (
          <Button onClick={onUp} forceColumn={forceColumn}>
            {theme.icons.arrowUp}
          </Button>
        )}
        {!last && (
          <Button onClick={onDown} forceColumn={forceColumn}>
            {theme.icons.arrowDown}
          </Button>
        )}
        {withContent && (
          <ContentButton onClick={onOpenContent} forceColumn={forceColumn}>
            {theme.icons.text}
          </ContentButton>
        )}
      </Actions>
      {data.file ? (
        <Image src={URL.createObjectURL(data.file)} alt={data.alt} />
      ) : data.type === 'video' ? (
        <Image src="/videoPlaceholder.jpg" alt={data.alt} />
      ) : (
        <Image src={data.url} alt={data.alt} />
      )}
      {withContent && contentMediaType ? (
        <Modal open={openContent} onClose={onCloseContent}>
          {renderContent(contentMediaType)}
        </Modal>
      ) : null}
    </Root>
  );
};

export default Media;
