import React from 'react';
import { FormattedMessage } from 'react-intl';

const translate = (id, value = {}) => (
  <FormattedMessage id={id} values={{ ...value }} defaultMessage={id} />
);

// Example: translate("hello", {name: "My name"})

export default translate;
