import React from 'react';
import styled from 'styled-components';
import translate from '../../../providers/i18n/translate';

/* Styled components */
const Root = styled.td`
  width: auto;
  display: ${(props) => (props.hidden ? 'none' : props.hiddenMobile ? 'none' : 'table-cell')};
  column-span: ${(props) => (props.colspan ? props.colspan : 'inherit')};
  text-align: ${(props) => (props.align ? props.align : 'left')};
  line-height: 2rem;
  font-size: 0.85rem;
  padding: 0 10px 0 0;
  border-bottom: 2px solid #c6c6c680;
  color: ${(props) => (props.color ? props.color : '#000')};
  @media (min-width: 400px) {
    display: ${(props) => (props.hidden ? 'none' : 'table-cell')};
    width: ${(props) => (props.width ? props.width : 'auto')};
    line-height: 1.5rem;
  }
`;

const Cell = ({ children, width, hidden, hiddenMobile, colspan, align, color }) => {
  return (
    <Root
      width={width}
      hidden={hidden}
      hiddenMobile={hiddenMobile}
      colspan={colspan}
      align={align}
      color={color}
    >
      {children}
    </Root>
  );
};

export default Cell;
