export const alertMessage = (msg, type) => {
  return (dispatch) => {
    dispatch({
      type: type ? type : 'ALERT_CLEAR',
      message: msg,
    });
  };
};

export const loadingAction = (type) => {
  return (dispatch) => {
    dispatch({
      type: type ? type : 'LOADING_ERROR',
    });
  };
};

export const loadingProgress = (type, progress) => {
  return (dispatch) => {
    dispatch({
      type: type ? type : 'LOADING_PROGRESS',
      progress: progress,
    });
  };
};
