import React from 'react';
import styled from 'styled-components';
import translate from '../providers/i18n/translate';
import { ThemeContext } from '../providers/theme';

const Root = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: ${(props) => (props.color ? props.color : '#000')};
`;

export default function AccessDeniedPage() {
  const theme = React.useContext(ThemeContext);

  return (
    <Root color={theme.palette.base}>
      <h1>{translate('access denied to page')}</h1>
    </Root>
  );
}
