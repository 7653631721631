import React from 'react';
import styled from 'styled-components';
import { Table, SearchInput } from '../components/tables/table';
import translate from '../providers/i18n/translate';
import { ThemeContext } from '../providers/theme';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { API_BACKEND } from '../config';
import AddIcon from '@mui/icons-material/Add';
import NewLayout from '../components/layoutConfig/newLayout';
import { Modal } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

/* Styled components */
const Root = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 50px 1fr;
  height: 100%;
  width: 100%;
  padding: 0 20px;
  @media (min-width: 1024px) {
    padding: 0 50px;
  }
`;

const Title = styled.h2`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  color: ${(props) => (props.color ? props.color : '#000')};
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`;

const Content = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const NewIcon = styled.div`
  position: sticky;
  right: 20px;
  top: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 50%;
  background-color: ${(props) => (props.bg ? props.bg : '7A7A7A')};
  color: ${(props) => (props.color ? props.color : '#FFF')};
  cursor: pointer;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  &:hover {
    transform: scale(1.1);
  }
  z-index: 99;
  @media (min-width: 1024px) {
    right: 50px;
    bottom: 50px;
  }
`;

const VisibilityOffIconLayout = styled.div`
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export default function LayoutsPage() {
  const theme = React.useContext(ThemeContext);

  const tableRef = React.useRef();

  const navigate = useNavigate();

  const intl = useIntl();

  const [search, setSearch] = React.useState('');

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    tableRef.current.clean();
    setOpen(false);
  };

  const handleSelectItem = (data) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(intl.formatMessage({ id: 'confirm select' }))) {
      axios
        .post(`${API_BACKEND}/layouts/selected/${data.id}`)
        .then((result) => {
          tableRef.current.clean();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const selectLayoutIcon = (d) => {
    return d.selected === true ? (
      <VisibilityIcon />
    ) : (
      <VisibilityOffIconLayout
        title={intl.formatMessage({ id: 'select layout' })}
        onClick={() => handleSelectItem(d)}
      >
        {' '}
        <VisibilityOffIcon />
      </VisibilityOffIconLayout>
    );
  };

  const columnsTable = [
    {
      title: translate('name'),
      field: 'name',
      order: true,
      width: '30%',
      hidden: false,
    },
    {
      title: translate('selected'),
      field: 'selected',
      render: selectLayoutIcon,
      order: false,
      width: '30%',
      hidden: false,
    },
    {
      title: translate('actions'),
      actions: true,
      width: 'auto',
      hidden: false,
    },
  ];

  const handleNewItem = (data) => {
    handleOpen();
  };

  const handleDeleteItem = (data) => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(intl.formatMessage({ id: 'confirm delete' }))) {
      axios
        .delete(`${API_BACKEND}/layouts/${data.id}`)
        .then((result) => {
          tableRef.current.clean();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onSearchChange = (value) => {
    setSearch(value);
  };

  const handleSearch = () => {
    tableRef.current.clean();
  };

  const handleSettingItem = (data) => {
    navigate(`/admin/layouts/${data.id}`);
  };

  const actionsTable = {
    view: {
      icon: theme.icons.settings,
      tooltip: 'Configurar',
      onClick: handleSettingItem,
      color: theme.palette.principal,
      hoverColor: theme.palette.accent,
      hidden: false,
    },
    delete: {
      icon: theme.icons.delete,
      tooltip: 'Eliminar',
      onClick: handleDeleteItem,
      color: theme.palette.principal,
      hoverColor: theme.palette.accent,
      hidden: false,
    },
  };

  return (
    <Root>
      <Title color={theme.palette.principal}>{translate('configuration')}</Title>

      <Content>
        <Table
          ref={tableRef}
          columns={columnsTable}
          actionsTable={actionsTable}
          viewTable="list"
          data={(query) =>
            new Promise((resolve, reject) => {
              axios
                .get(`${API_BACKEND}/layouts`, {
                  params: {
                    page: query.page - 1,
                    limit: query.pageSize,
                    search: search,
                    order: query.order,
                  },
                })
                .then((config) => {
                  const [items, total] = config.data;
                  resolve({
                    rows: items,
                    total,
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            })
          }
        ></Table>
        <NewIcon color={theme.palette.base} bg={theme.palette.accent} onClick={handleNewItem}>
          <AddIcon />
        </NewIcon>
        <Modal open={open} onClose={handleClose}>
          <NewLayout handleClose={handleClose} />
        </Modal>
      </Content>
    </Root>
  );
}
