import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import KeyIcon from '@mui/icons-material/Key';
import AddIcon from '@mui/icons-material/Add';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LogoutIcon from '@mui/icons-material/Logout';
import GridViewIcon from '@mui/icons-material/GridView';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import MenuIcon from '@mui/icons-material/Menu';
import ApiIcon from '@mui/icons-material/Api';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import CheckIcon from '@mui/icons-material/Check';
import RemoveIcon from '@mui/icons-material/Remove';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HexagonIcon from '@mui/icons-material/Hexagon';
import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import HolidayVillageIcon from '@mui/icons-material/HolidayVillage';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ExtensionIcon from '@mui/icons-material/Extension';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import MailIcon from '@mui/icons-material/Mail';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import ViewComfy from '@mui/icons-material/ViewComfy';
import ViewQuiltRounded from '@mui/icons-material/ViewQuiltRounded';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { NavigateBefore } from '@mui/icons-material';
import { NavigateNext } from '@mui/icons-material';

export const THEMES = {
  default: {
    palette: {
      principal: '#3A3A3A',
      base: '#FFFFFF',
      accent: '#c1a100',
      details: '#707070',
      alternative_principal: '#3A3A3A',
      alternative_base: '#FFFFFF',
      alternative_accent: '#c1a100',
      alternative_details: '#D7D7D7',
      error: '#DA1414',
      success: '#2E5AAC',
      warning: '#B95000',
    },
    icons: {
      view: <VisibilityIcon />,
      add: <AddIcon />,
      edit: <EditIcon />,
      delete: <DeleteForeverIcon />,
      password: <KeyIcon />,
      notifications: <NotificationsIcon />,
      notificationsActive: <NotificationsActiveIcon />,
      person: <PersonIcon />,
      settings: <SettingsIcon />,
      arrowBack: <ArrowBackIcon />,
      logout: <LogoutIcon />,
      gridView: <GridViewIcon />,
      accountCircle: <AccountCircleOutlinedIcon />,
      calendar: <CalendarTodayIcon />,
      menu: <MenuIcon />,
      api: <ApiIcon />,
      wallet: <AccountBalanceWalletIcon />,
      sale: <PointOfSaleIcon />,
      check: <CheckIcon />,
      remove: <RemoveIcon />,
      tree: <AccountTreeIcon />,
      exit: <ExitToAppIcon />,
      hexagon: <HexagonIcon />,
      info: <InfoIcon />,
      alert: <ErrorIcon />,
      close: <CloseIcon />,
      facebook: <FacebookIcon />,
      'facebook.svg': <FacebookIcon />,
      'twitter.svg': <TwitterIcon />,
      youtube: <YouTubeIcon />,
      instagram: <InstagramIcon />,
      'instagram.svg': <InstagramIcon />,
      whatsapp: <WhatsAppIcon />,
      linkedin: <LinkedInIcon />,
      mail: <MailIcon />,
      location: <LocationOnIcon />,
      phone: <PhoneIcon />,
      lodgings: <HolidayVillageIcon />,
      pubs: <MenuBookIcon />,
      arrowUp: <ArrowDropUpIcon />,
      arrowDown: <ArrowDropDownIcon />,
      plugin: <ExtensionIcon />,
      pages: <LibraryBooksIcon />,
      layout: <ViewQuiltRounded />,
      sections: <NewspaperIcon />,
      back: <KeyboardBackspaceIcon />,
      text: <FormatAlignJustifyIcon />,
      subdirectory: <SubdirectoryArrowRightIcon />,
      navigateBefore: <NavigateBefore />,
      navigateNext: <NavigateNext />,
    },
  },
};
