import React from 'react';
import styled from 'styled-components';

const Root = styled.tfoot`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  svg {
    cursor: pointer;
  }
`;

const Foot = ({ children }) => {
  return <Root>{children}</Root>;
};

export default Foot;
