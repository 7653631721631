import { LOCALES } from '../locales';

const spanish = {
  [LOCALES.SPANISH]: {
    hello: "Hola, {name}!",
    exit: "Salir",
    "app.login.welcome": "Bienvenido",
    "app.login.signin": "Iniciar sesión",
    "app.login.donthaveaccount": "¿Aún no tienes cuenta?",
    "app.login.loginaccount": "Entrá a tu cuenta",
    "app.login.signup": "Registrate aquí",
    "app.register.welcome": "Comencemos",
    "app.register.registeraccount": "Creá tu cuenta",
    "app.register.signup": "Registrate",
    "app.register.haveanaccount": "¿Ya tenés una cuenta?",
    "app.register.signin": "Iniciá sesión aquí",
    username: "Nombre de usuario",
    password: "Contraseña",
    Username: "Nombre de usuario",
    Password: "Contraseña",
    Welcome: "Bienvenido al sistema de administración",
    "Configure statistics": "Configure las estadísticas",
    mole: "Mole",
    total: "Total",
    "confirm delete": "¿Seguro que desea eliminar?",
    records: "registros",
    show: "Mostrar",
    of: "de",
    page: "Página",
    actions: "Acciones",
    email: "Correo electrónico",
    lastName: "Apellidos",
    firstName: "Nombres",
    users: "Usuarios",
    "Configure link to official website": "Configure enlace al sitio oficial",
    sections: "Secciones",
    author: "Autor",
    slug: "Slug",
    title: "Título",
    "Post date": "Fecha publicación",
    type: "Tipo",
    subtitle: "Subtítulo",
    apply: "Aplicar",
    posts: "Publicaciones",
    lodgings: "Alojamientos",
    Lodgings: "Alojamientos",
    dashboard: "Dashboard",
    description: "Descripción",
    name: "Nombre",
    Posts: "Publicaciones",
    search: "Buscar",
    "insert cancellation description here...":
      "Insertart descripción de cancelación aquí...",
    "insert description here...": "Insertar descripción aquí...",
    Amenities: "Amenities",
    Gallery: "Galería",
    "General data": "Datos Generales",
    cancel: "Cancelar",
    "new lodging": "Nuevo alojamiento",
    "insert check in description here...":
      "Insertar descripción del Check In aquí...",
    epigraph: "Epígrafe",
    "upload photo": "Subir imagen",
    "choose file": "Seleccionar archivo",
    "page created": "Página creada",
    Pages: "Páginas",
    "new page": "Nueva página",
    "edit page": "Editar página",
    "page edited": "Página editada",
    "new section": "Nueva sección",
    "edit section": "Editar sección",
    "section type": "Tipo de sección",
    "You must select a type to complete the section information...":
      "Debe seleccionar un tipo de sección para completar su información...",
    order: "Orden",
    Sections: "Secciones",
    pages: "páginas",
    "This section does not need more information.":
      "Esta sección no necesita más información.",
    ampMap: "Mapa",
    newsletter: "Newsletter",
    newsletterV2: "Newsletter V2",
    twoColumnsPubs: "Publicación de dos columnas",
    textSection: "Sección de texto",
    bookWidget: "Widget Reservar",
    heroBanner: "Banner Hero",
    heroBannerHalf: "Half Hero Banner",
    "insert text here...": "Inserte el texto aquí...",
    heroImage: "Imagen Hero",
    cardsBand: "Banda de tarjetas",
    cardsGrid: "Grilla de tarjetas",
    cardsGridV2: "Grilla de tarjetas V2",
    ampGalleryCarousel: "Galería Carrusel",
    "back to pages": "Volver a páginas",
    "config section": "Configurar sección",
    configuration: "Configuración",
    "title must not be empty": "Título no debe estar vacío",
    "subtitle must not be empty": "Subtitulo no debe estar vacío",
    "button text must not be empty": "El texto del botón no debe estar vacío",
    "origin must not be empty": "El rigen no debe estar vacío",
    "section edited": "Sección editada",
    "alternative text": "Texto alternativo",
    link: "Enlace",
    "button link": "Enlace del botón",
    "text button": "Texto del botón",
    photo: "Imagen",
    upload: "Subir",
    "vertical text align": "Alineación vertical texto",
    "horizontal text align": "Alineación horizontal texto",
    middle: "Medio",
    left: "Izquierda",
    right: "Derecha",
    "to left": "Hacia Izquierda",
    "to right": "Hacia Derecha",
    "to top": "Hacia Arriba",
    "to bottom": "Hacia Abajo",
    change: "Cambiar",
    remove: "Borrar",
    "horizontal text align must not be empty":
      "Alineación horizontal del texto no debe ser vacío",
    "vertical text align must not be empty":
      "Alineación vertical del texto no debe ser vacío",
    "image must not be empty": "La imagen no debe ser vacío",
    "section saved": "Sección guardada",
    "edit content": "Editar contenido",
    "add content": "Agregar contenido",
    text: "Texto",
    "text must not be empty": "Texto no debe estar vacío",
    "image height": "Altura de imagen",
    "image height should be between 250 and 400":
      "Altura de imagen debe ser entre 250 y 400",
    "src map must not be empty": "Origen del mapa (url) no debe ser vacío",
    "src map": "Origen del mapa (url)",
    "post type": "Tipo de publicación",
    "postType must not be empty": "Tipo de publicación no debe ser vacío",
    "section created": "Sección creada",
    Activities: "Actividades",
    Services: "Servicios",
    Promociones: "Promociones",
    Publish: "Publicar",
    Draft: "Borrador",
    draft: "Borrador",
    status: "Estado",
    "insert content here...": "Insertar contenido aquí...",
    "new post": "Nueva publicacion",
    "post type must not be empty": "Tipo de publicación no debe ser vacío",
    "status must not be empty": "Estado no debe ser vacío",
    "slug must not be empty": "Slug no debe ser vacío",
    "date must not be empty": "Fecha no debe ser vacío",
    "author must not be empty": "Autor no debe ser vacío",
    "edit post": "Editar publicación",
    Published: "Publicado",
    published: "Publicado",
    "feature persons": "Característica personas",
    "feature m2": "Característica m2",
    "feature bathroom": "Característica baños",
    "feature landscape": "Característica vista",
    "feature sound": "Característica sonido",
    "feature image": "Característica imagen",
    "free feature": "Característica libre",
    heroImageRoom: "Imagen Hero de Habitación",
    "button text": "Texto del botón",
    "post created": "Publicación creada",
    "Executive Lunches": "Almuerzos ejecutivos",
    Events: "Eventos",
    Basilico: "Basilico",
    Promotions: "Promociones",
    lodgingDescription: "Descripción del alojamiento",
    "avoid accents and strange symbols": "Evite tildes y simbolos raros",
    outstanding: "Destacado",
    "post updated": "Publicación actualizada",
    "name must not be empty": "Nombre no debe ser vacío",
    "path must not be empty": "Camino no debe ser vacío",
    path: "Camino",
    textSectionV2: "Sección de texto versión 2",
    heroImageV2: "Imagen Hero Versión 2",
    twoColumnsCard: "Tarjeta de dos columnas",
    ampGalleryCarouselV2: "Galería Carrusel Versión 2",
    cardsBandV2: "Banda de tarjetas Versión 2",
    cardsBandV3: "Cards band Version 3",
    cardsBandV4: "Cards band Version 4",
    cardsBandV5: "Cards band Version 5",
    cardsBandV6: "Cards band Version 6",
    cardsBandV7: "Cards band Version 7",
    lodgingDescriptionV2: "Descripción del alojamiento Versión 2",
    pretitle: "Pretítulo",
    "title 1 must not be empty": "Título 1 no debe ser vacío",
    "text 1 must not be empty": "Texto 1 no debe ser vacío",
    "title 2 must not be empty": "Título 2 no debe ser vacío",
    "text 2 must not be empty": "Texto 2 no debe ser vacío",
    "pdf must not be empty": "PDF no debe ser vacío",
    pdf: "PDF",
    gridSection: "Sección grilla",
    menuPresenter: "Presentador de Menu",
    "iframe book": "Iframe de la reserva",
    "iframe book must not be empty": "Iframe de la reserva no debe ser vacío",
    height: "Altura",
    "height must not be empty": "La altura no pude ser vacia",
    "width must not be empty": "El ancho no pude ser vacil",
    "mobile height": "Altura mobile",
    "mobile width": "Ancho mobile",
    "tablet height": "Altura tablet",
    "desktop height": "Altura desktop",
    "desktop width": "Ancho desktop",
    "desktop plus height": "Altura desktop grande",
    "image from file": "Imagen desde archivo",
    "video from file": "Video desde archivo",
    "video from URL": "Video desde URL",
    "select src": "Seleccionar fuente",
    "select position": "Seleccionar posición",
    invert: "Invertir",
    youtube: "Youtube",
    vimeo: "Vimeo",
    "select video source": "Seleccionar fuente del video",
    "source video must not be empty": "Fuente del video no debe ser vacío",
    "video link must not be empty": "Enlace del video no debe ser vacío",
    "section title": "Título de sección",
    projects: "Desarrollos",
    tag: "Etiqueta",
    tags: "Etiquetas",
    logo: "Logo",
    "new project": "Nuevo Desarrollo",
    "project updated": "Desarrollo actualizado",
    "project created": "Desarrollo creado",
    "edit project": "Editar desarrollo",
    "new amenitie": "Nuevo amenitie",
    "two columns section": "Sección dos columnas",
    "source select must not be empty":
      "Seleccionar la fuente no debe ser vacío",
    manual: "Manual",
    auto: "Auto",
    "select autoload": "Seleccionar autoload",
    "select autoload must not be empty":
      "Seleccionar autoload no debe ser vacío",
    "link must not be empty": "Enlace no debe ser vacío",
    subject: "Asunto",
    "subject must not be empty": "Asunto no debe ser vacío",
    from: "De",
    to: "Para",
    "from address must not be empty":
      "De no debe ser una dirección de correo vacía",
    "to address must not be empty":
      "Para no debe ser una dirección de correo vacía",
    textButton: "Texto del botón",
    "insert email header here...": "Escriba el encabezado del email aquí...",
    "insert email signature here...": "Escriba la firma del email aquí...",
    formSection: "Sección Formulario",
    formWorkWithUs: "Formulario Trabajá con nosotros",
    "four columns section": "Sección de cuatro columnas",
    "principal title": "Título principal",
    "timeline section": "Sección línea de tiempo",
    "two columns wide image section":
      "Sección de dos columnas con imagen horizontal",
    "map section": "Sección mapa",
    mapSection: "Sección mapa",
    "form section": "Sección formulario",
    address: "Dirección",
    "modal section": "Sección Modal",
    "blueprints section": "Sección planos",
    "floors details section": "Sección de detalles de los pisos",
    "delete section": "Eliminar sección",
    "add head field": "Agregar columna",
    "add row": "Agregar fila",
    "add section": "Agregar sección",
    mobSideMargin: "Margen lateral mobile",
    deskSideMargin: "Margen lateral desktop",
    mobBottomMargin: "Margen inferior mobile",
    deskBottomMargin: "Margen inferior desktop",
    "margin must not be empty": "El margen no debe ser vacío",
    "url must not be empty": "El url no debe ser vacío",
    "position must not be empty": "La posición no debe ser vacía",
    modal: "Modal",
    "insert title here...": "Inserte el título aquí...",
    "insert subtitle here...": "Inserte el subtítulo aquí...",
    "text vertical position": "Posición vertical del texto",
    "text horizontal position": "Posición horizontal del texto",
    "title horizontal position": "Posición horizontal del título",
    "text button horizontal position":
      "Posición horizontal del texto del botón",
    top: "Arriba",
    center: "Centro",
    bottom: "Abajo",
    "custom gradient color": "Color de gradiente personalizado",
    GradientDirection: "Dirección del gradiente",
    "custom title color": "Color de título personalizado",
    "title color": "Color de título",
    "custom subtitle color": "Color de subtítulo personalizado",
    icons: "Íconos",
    url: "Enlace",
    "background color": "Color del fondo",
    cards: "Tarjetas",
    start: "Inicio",
    end: "Fin",
    area: "Área",
    events: "Eventos",
    "new event": "Nuevo evento",
    "edit event": "Editar evento",
    twoColumnsTextCarousel: "Dos columnas con carousel",
    "job title": "Cargo",
    quote: "Cita",
    image: "Imagen",
    video: "Video",
    "content type": "Tipo de contenido",
    nameButtonJobs: "Nombre botón busquedas trabajo",
    linkButtonJobs: "Link botón busquedas trabajo",
    "lateral multimedia": "Multimedia lateral",
    conection: "Conección",
    "desktop image": "Imagen desktop",
    "mobile image": "Imagen mobile",
    delay: "Tiempo de espera",
    newTab: "Nueva pestaña",
    borderRadiusImage: "Borde redondeado imagen",
    stackedImagesSliderV: "Slider Testimonios con Img",
    stackedImagesSliderV2: "Slider Testimonios con Img V2",
    stackedImagesSliderV3: "Slider Testimonios con Img V3",
    stackedImagesSliderV4: "Slider Testimonios con Img V4",
    basicTestimony: "Basic Testimony",
    faqAccordion: "Acordion FAQ",
    answer: "Respuesta",
    question: "Pregunta",
    "answer&questions": "Preguntas y Respuestas",
    faq: "FAQ",
    year: "Año",
    eventsTimeline: "Eventos en línea de tiempo",
    eventsTimelineV2: "Eventos en línea de tiempo V2",
    origin: "Origen",
    thanksSubtitle: "Subtitulo de agradecimiento",
    thanksTitle: "Titulo de agradecimiento",
    testimonies: "Testimonios",
    newTestimony: "Nuevo testimonio",
    editTestimony: "Editar testimonio",
    testimony: "Testimonio",
    dividerWithTransition: "Divisor con transición",
    divider: "Divisor",
    borderRadius: "Redondeo de bordes",
    topLeft: "Arriba izquierda",
    topRight: "Arriba derecha",
    bottomLeft: "Abajo izquierda",
    bottomRight: "Abajo derecha",
    backgroundColorTop: "Color de fondo arriba",
    gradientColor: "Color de gradiente",
    backgroundColorBotton: "Color de fondo abajo",
    layout: "Layout",
    selected: "Seleccionado",
    "confirm select": "¿Desea confirmar esta acción?",
    "select layout": "Seleccionar layout",
    "layout name": "Nombre del Layout",
    paddingTop: "Margen interior  superior",
    paddingRight: "Margen interior derecho",
    paddingBottom: "Margen interior inferior",
    paddingLeft: "Margen interior izquierdo",
    "menus gap": "Separación de los menús",
    background: "Fondo",
    "new tab": "Nueva pestaña",
    width: "Ancho",
    align: "Alinear",
    minHeight: "Mínima altura",
    maxHeight: "Máxima altura",
    gap: "Separación",
    Logo: "Logo",
    Style: "Estilo",
    footer: "Pie de página",
    info: "Información",
    rows: "Filas",
    "social icons": "Íconos sociales",
    Icons: "Iconos",
    navbar: "Barra de navegación",
    style: "estilo",
    layout_intro_navbar:
      "A continuación puedes configurar los diferentes elementos de la barra de navegación.",
    layout_intro_footer:
      "A continuación puedes configurar los diferentes elementos del pie de página.",
    "layout updated": "Layout actualizado",
    menus: "Menús",
    submenus: "Submenús",
    "select page": "Seleccione la página",
    "select link type": "Seleccione el tipo de enlace",
    "section link": "Enlace de sección",
    "external link": "Enlace externo",
    "internal link": "Enlace interno",
    edit: "Editar",
    create: "Crear",
    new: "Nuevo",
    "social icon type": "Tipo de ícono social",
    verticalArrangement: "Disposición vertical",
    "initial background color": "Color de fondo inicial",
    "end background color": "Color de fondo final",
    marginLeft: "Margen izquierdo",
    marginRight: "Margen derecho",
    textColor: "Color del texto",
    "contentType media not supported": "Media ContentType no soportado",
    "button text color": "Color del texto del botón",
    "button hover text color": "Color hover del texto del botón",
    "button color": "Color del botón",
    "button hover color": "Color hover del botón",
    "button border color": "Color del borde del botón",
    cardTextColor: "Color del texto de la tarjeta",
    "card title color": "Color del título de la tarjeta",
    curriculum: "Temario",
    "epigraph text": "Epígrafe",
    "person in charge emails": "Correos electrónicos de personas a cargo",
    "person in charge": "Persona a cargo",
    "starting date": "Fecha de inicio",
    duration: "Duración",
    price: "Precio",
    "course type": "Tipo de curso",
    "type must not be empty": "Tipo no debe ser vacío",
    "epigraph must not be empty": "Epígrafe no debe ser vacío",
    "alter must not be empty": "Texto alternativo no debe ser vacío",
    "responsibleEmails must not be empty":
      "Correos electrónicos de responsables no debe ser vacío",
    "course media": "Multimedia del curso",
    "edit course": "Editar curso",
    courses: "Cursos",
    "edit item": "Editar tema",
    content: "Contenido",
    "new course": "New course",
    "new item": "New item",
    padding: "Padding",
    paddingMob: "Padding Mob",
    paddingTab: "Padding Tab",
    paddingDesk: "Padding Desk",
    aspectRatio: "Relación de aspecto",
    "author avatar": "Avatar del autor",
    "add multimedia": "Agregar Multimedia",
    "multimedia type": "Tipo de Multimedia",
    contactInfo: "Información de contacto",
    titleColor: "Color del título",
    subtitleColor: "Color del subtítulo",
    twoColumnsCarousel: "Dos columnas con carrusel",
    "error required field": "Campo requerido",
    "btn-primary": "Primario",
    "btn-secondary": "Secundario",
    "btn-primary-outlined": "Primario Outlined",
    "btn-secondary-outlined": "Secundario Outlined",
    "type button": "Tipo de botón",
    badges: "Insignias",
    dinamicTable: "Tabla dinámica",
    blockFirstColumn: "Bloquear primera columna",
    pubsGolf: "Pubs Golf",
    pubsUlu: "Pubs Ulu",
    "multimedia gallery": "Galería Multimedia",
    gradient: "Gradiente",
    "select section": "Seleccione una sección",
    "link type must not be empty": "El tipo de enlace no debe ser vacío",
    "page must not be empty": "La página no debe ser vacía",
    "section must not be empty": "La sección no debe ser vacía",
    "invert columns": "Invertir columnas",
    "mobile gap": "Gap mobile",
    "tablet gap": "Gap tablet",
    "desktop gap": "Gap desktop",
    "large desktop gap": "Gap desktop grande",
    "top distance": "Distancia superior",
    bgColor: "Color del fondo",
  },
};

export default spanish;
