import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../store/action-creators';
import { alertConstants } from '../../../store/reducers/messageBar/alertConstants';
import axios from 'axios';
import { API_BACKEND } from '../../../config';
import InputImage from '../../inputs/inputImage';
import { useIntl } from 'react-intl';
import { TextField } from '@mui/material';
import SelectInput from '../../inputs/SelectInput';
import InputAdornment from '@mui/material/InputAdornment';

const Root = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  max-height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 60% 40%;
  }
`;

const InputsContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: ${(props) => props.width ?? '100%'};
  height: min-content;
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-grow: 0;
  flex-direction: column;
  width: ${(props) => props.width ?? '100%'};
  height: auto;
`;

const ContainerImgWithAlt = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  grid-column: 2/3;
  grid-row: 1/6;
`;

const ImageContainer = styled.div`
  width: 300px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const StyledTextfield = styled(TextField)`
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : 'inherit')};
  }
`;

const ChatButton = ({ data, setData }) => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const { alertMessage } = bindActionCreators(actionCreators, dispatch);

  const onChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChangeAutocomplete = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const onChangeImage = (files) => {
    if (files.length > 0) {
      const formData = new FormData();
      formData.append('image', files[0]);
      axios
        .post(`${API_BACKEND}/sections/images`, formData, {})
        .then((res) => {
          setData({
            ...data,
            logo: res.data.url,
          });
        })
        .catch((error) => {
          alertMessage(error.response?.data?.msg, alertConstants.ERROR);
        });
    }
  };

  const onResetImage = () => {
    setData({
      ...data,
      logo: null,
    });
  };

  const selectOpt = [
    {
      label: intl.formatMessage({ id: 'right' }),
      value: 'right',
    },
    {
      label: intl.formatMessage({ id: 'left' }),
      value: 'left',
    },
  ];

  return (
    data && (
      <Root>
        <InputsContainer width="100%">
          <InputContainer width="45%">
            <StyledTextfield
              id="deskHeight"
              name="deskHeight"
              onChange={onChange}
              value={data?.deskHeight}
              label={intl.formatMessage({ id: 'desktop height' })}
              variant="outlined"
              width="100%"
              margin="normal"
              size="small"
              InputProps={{
                endAdornment: <InputAdornment position="end">px</InputAdornment>,
              }}
              required
            />
          </InputContainer>
          <InputContainer width="45%">
            <StyledTextfield
              id="deskWidth"
              name="deskWidth"
              onChange={onChange}
              value={data?.deskWidth}
              label={intl.formatMessage({ id: 'desktop width' })}
              variant="outlined"
              width="100%"
              margin="normal"
              size="small"
              InputProps={{
                endAdornment: <InputAdornment position="end">px</InputAdornment>,
              }}
              required
            />
          </InputContainer>
          <InputContainer width="45%">
            <StyledTextfield
              id="mobHeight"
              name="mobHeight"
              onChange={onChange}
              value={data?.mobHeight}
              label={intl.formatMessage({ id: 'mobile height' })}
              variant="outlined"
              width="100%"
              margin="normal"
              size="small"
              InputProps={{
                endAdornment: <InputAdornment position="end">px</InputAdornment>,
              }}
              required
            />
          </InputContainer>
          <InputContainer width="45%">
            <StyledTextfield
              id="mobWidth"
              name="mobWidth"
              onChange={onChange}
              value={data?.mobWidth}
              label={intl.formatMessage({ id: 'mobile width' })}
              variant="outlined"
              width="100%"
              margin="normal"
              size="small"
              InputProps={{
                endAdornment: <InputAdornment position="end">px</InputAdornment>,
              }}
              required
            />
          </InputContainer>
          <InputContainer width="45%">
            <StyledTextfield
              id="mobSideMargin"
              name="mobSideMargin"
              onChange={onChange}
              value={data?.mobSideMargin}
              label={intl.formatMessage({ id: 'mobSideMargin' })}
              variant="outlined"
              width="100%"
              margin="normal"
              size="small"
              InputProps={{
                endAdornment: <InputAdornment position="end">px</InputAdornment>,
              }}
              required
            />
          </InputContainer>
          <InputContainer width="45%">
            <StyledTextfield
              id="deskSideMargin"
              name="deskSideMargin"
              onChange={onChange}
              value={data?.deskSideMargin}
              label={intl.formatMessage({ id: 'deskSideMargin' })}
              variant="outlined"
              width="100%"
              margin="normal"
              size="small"
              InputProps={{
                endAdornment: <InputAdornment position="end">px</InputAdornment>,
              }}
              required
            />
          </InputContainer>
          <InputContainer width="45%">
            <StyledTextfield
              id="mobBottomMargin"
              name="mobBottomMargin"
              onChange={onChange}
              value={data?.mobBottomMargin}
              label={intl.formatMessage({ id: 'mobBottomMargin' })}
              variant="outlined"
              width="100%"
              margin="normal"
              size="small"
              InputProps={{
                endAdornment: <InputAdornment position="end">px</InputAdornment>,
              }}
              required
            />
          </InputContainer>
          <InputContainer width="45%">
            <StyledTextfield
              id="deskBottomMargin"
              name="deskBottomMargin"
              onChange={onChange}
              value={data?.deskBottomMargin}
              label={intl.formatMessage({ id: 'deskBottomMargin' })}
              variant="outlined"
              width="100%"
              margin="normal"
              size="small"
              InputProps={{
                endAdornment: <InputAdornment position="end">px</InputAdornment>,
              }}
              required
            />
          </InputContainer>
          <InputContainer width="100%">
            <StyledTextfield
              id="url"
              name="url"
              onChange={onChange}
              value={data?.url}
              label={intl.formatMessage({ id: 'url' })}
              variant="outlined"
              width="100%"
              margin="normal"
              size="small"
              required
            />
          </InputContainer>
        </InputsContainer>
        <ContainerImgWithAlt>
          <ImageContainer>
            <InputImage
              id="logo"
              file={data.logo}
              onChange={onChangeImage}
              onReset={onResetImage}
            />
          </ImageContainer>
          <SelectInput
            data={selectOpt}
            value={data?.position?.value}
            fieldlabel="label"
            fieldvalue="value"
            label={intl.formatMessage({ id: 'select position' })}
            name="position"
            onChange={handleChangeAutocomplete}
            width="50%"
            margin="normal"
            size="small"
            required
          />
        </ContainerImgWithAlt>
      </Root>
    )
  );
};

export default ChatButton;
