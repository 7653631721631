import React from 'react';
import styled from 'styled-components';
import { ThemeContext } from '../../../../providers/theme';
import AddLinkIcon from '@mui/icons-material/AddLink';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { API_FRONTEND } from '../../../../config';

const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px 30px 10px 20px;
  max-width: 200px;
  min-width: 100px;
  box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.3);
  margin: 5px;
  user-select: none;
`;

const Name = styled.span`
  margin: 5px 0;
  text-align: center;
`;

const Edit = styled.button`
  position: absolute;
  top: 5px;
  right: 25px;
  svg {
    font-size: 1rem;
  }
  background: transparent;
  border: none;
  cursor: pointer;
`;

const Delete = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  svg {
    font-size: 1rem;
  }
  background: transparent;
  border: none;
  cursor: pointer;
`;

const InfoLink = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
  svg {
    font-size: 1rem;
  }
  background: transparent;
  cursor: pointer;
`;

const Info = ({ text, link, index, handleDelete, handleEdit }) => {
  const theme = React.useContext(ThemeContext);
  const parseInfoLink = (link) => {
    return link.includes('http') ? link : `${API_FRONTEND}/${link}`;
  };

  return (
    <Root>
      <Name>{text}</Name>
      <Edit data-index={index} onClick={handleEdit}>
        {theme.icons.edit}
      </Edit>
      <Delete data-index={index} onClick={handleDelete}>
        {theme.icons.close}
      </Delete>
      <InfoLink>
        {link ? (
          <a href={parseInfoLink(link)} rel="noreferrer" target="_blank">
            <AddLinkIcon />
          </a>
        ) : (
          <LinkOffIcon />
        )}
      </InfoLink>
    </Root>
  );
};

export default Info;
