import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import translate from '../../providers/i18n/translate';
import TextField from '@mui/material/TextField';
import { useIntl } from 'react-intl';
import BasicEditor from '../textEditors/basicEditor';
import Amenities from './amenities';
import { API_BACKEND } from '../../config';
import InputGallery from '../inputGallery';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../store/action-creators';
import { alertConstants } from '../../store/reducers/messageBar/alertConstants';
import { useAuth } from '../../providers/authentication';
import { decodeToken } from '../../hooks/jwt';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px;
`;

const Header = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid black;
`;

const Title = styled.h2`
  text-transform: uppercase;
`;

const Subtitle = styled.h4`
  font-size: 1.5rem;
  width: 100%;
  color: ${(props) => props.theme.palette.details};
  border-bottom: 1px solid ${(props) => props.theme.palette.details};
  height: 50px;
`;

const Actions = styled.div`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: flex-end;
`;

const ActionButton = styled.button`
  background: transparent;
  border: none;
  text-transform: capitalize;
  border: 1px solid rgba(0, 0, 0, 0.5);
  padding: 8px 10px;
  cursor: pointer;
  border-radius: 8px;
  &:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
`;

const Container = styled.div`
  display: grid;
  width: 100%;
  height: calc(100% - 50px);
  grid-template-columns: 0.7fr 0.3fr;
  grid-template-rows: 0.7fr 0.3fr;
  gap: 20px;
  padding: 20px 0;
`;

const Box = styled.div`
  width: 100%;
  height: calc(100% - 50px);
`;

const MetaData = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const AmenitiesContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
  height: 100%;
`;

const GaleryContainer = styled(Box)`
  grid-column: 2/3;
  grid-row: 1/3;
`;

const LodgingDescriptions = styled.div`
  position: relative;
  display: grid;
  width: 100%;
  height: auto;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

const Description = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  grid-column: 1/3;
`;

const Instruction = styled.div`
  position: relative;
  width: 100%;
  height: auto;
`;

const StyledTextfield = styled(TextField)`
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : 'inherit')};
  }
`;

const NewLodging = () => {
  const { id } = useParams();

  const { token } = useAuth();

  const userData = decodeToken(token);

  const dispatch = useDispatch();
  const intl = useIntl();
  let navigate = useNavigate();

  const { alertMessage } = bindActionCreators(actionCreators, dispatch);
  const [editorLoaded, setEditorLoaded] = React.useState(false);

  const [data, setData] = React.useState(null);

  const galleryRef = React.useRef();

  const loadImages = () => {
    galleryRef.current.handleSubmit();
  };

  const parseImages = (toParse) => {
    return toParse.map((elem) => {
      return {
        id: elem.id,
        url: elem.image,
        alt: elem.alter,
        order: elem.order,
      };
    });
  };

  const [errors, setErrors] = React.useState({
    name: {
      value: false,
      text: intl.formatMessage({ id: 'title must not be empty' }),
      validate: (value) => value == null || value === '', // returns true if there is an error and false if there is not
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleEditorDescription = (e, dataeditor) => {
    setData({
      ...data,
      description: dataeditor,
    });
  };

  const handleEditorCheckInDescription = (e, dataeditor) => {
    setData({
      ...data,
      checkInDescription: dataeditor,
    });
  };

  const handleEditorCancellationDescription = (e, dataeditor) => {
    setData({
      ...data,
      cancellationDescription: dataeditor,
    });
  };

  const back = () => {
    navigate(-1);
  };

  const validateForm = () => {
    let foundError = false;
    const newErrors = {};
    Object.keys(errors).forEach((err) => {
      if (!foundError) {
        foundError = errors[err].validate(data[err]);
      }
      const val = errors[err].validate(data[err]);
      newErrors[err] = {
        ...errors[err],
        value: val,
      };
      setErrors({
        ...errors,
        ...newErrors,
      });
    });
    return !foundError;
  };

  const submit = (medias) => {
    if (validateForm()) {
      const formData = new FormData();
      const mediaData = medias.map((med) => {
        return {
          id: med.id,
          img: med.url,
          imgDesktop: med.url,
          imgTablet: med.url,
          imgMobile: med.url,
          alt: med.alt,
          order: med.order,
          data: med.data,
        };
      });
      const inputData = {
        ...data,
        cards: mediaData,
      };
      formData.append('name', data.name);
      formData.append('description', data.description);
      formData.append('checkInDescription', data.checkInDescription);
      formData.append('cancellationDescription', data.cancellationDescription);
      formData.append('filesDatas', null);
      formData.append('user', userData?.id);
      if (id) {
        axios
          .put(`${API_BACKEND}/lodgings/${id}`, formData, {})
          .then((res) => {
            alertMessage(intl.formatMessage({ id: 'lodging updated' }), alertConstants.INFO);
            navigate(-1);
          })
          .catch((error) => {
            alertMessage(error.response?.data?.msg, alertConstants.ERROR);
          });
      } else {
        axios
          .post(`${API_BACKEND}/lodgings`, formData, {})
          .then((res) => {
            alertMessage(intl.formatMessage({ id: 'lodging created' }), alertConstants.INFO);
            navigate(-1);
          })
          .catch((error) => {
            alertMessage(error.response?.data?.msg, alertConstants.ERROR);
          });
      }
    }
  };

  const handleAction = () => {
    loadImages();
  };

  React.useEffect(() => {
    if (!data) {
      if (id) {
        axios
          .get(`${API_BACKEND}/lodgings/${id}`)
          .then((res) => {
            const lodging = res.data;
            setData({
              name: lodging.name,
              description: lodging.description,
              checkInDescription: lodging.checkInDescription,
              cancellationDescription: lodging.cancellationDescription,
              images: lodging.images,
            });
          })
          .catch((error) => {
            alertMessage(error.response?.data?.msg, alertConstants.ERROR);
          });
      } else {
        setData({
          name: '',
          description: '',
          checkInDescription: '',
          cancellationDescription: '',
        });
      }
    }
    if (data) {
      setEditorLoaded(true);
    }
  }, [data]);

  return (
    <Root>
      <Header>
        <Title>{id ? translate('edit lodging') : translate('new lodging')}</Title>
        <Actions>
          <ActionButton onClick={handleAction}>{translate('apply')}</ActionButton>
          <ActionButton onClick={back}>{translate('cancel')}</ActionButton>
        </Actions>
      </Header>
      {data && (
        <Container>
          <MetaData>
            <Subtitle>{translate('General data')}</Subtitle>
            <StyledTextfield
              id="name"
              name="name"
              onChange={handleChange}
              value={data.name}
              label={intl.formatMessage({ id: 'name' })}
              variant="outlined"
              width="100%"
              margin="dense"
              size="small"
              required
            />
            <LodgingDescriptions>
              <Description>
                <BasicEditor
                  name="description"
                  value={data.description}
                  onChange={handleEditorDescription}
                  editorLoaded={editorLoaded}
                  maxContentHeight="150px"
                  minContentHeight="150px"
                  placeholder={intl.formatMessage({ id: 'insert description here...' })}
                />
              </Description>
              <Instruction>
                <BasicEditor
                  name="checkInDescription"
                  value={data.checkInDescription}
                  onChange={handleEditorCheckInDescription}
                  editorLoaded={editorLoaded}
                  maxContentHeight="150px"
                  minContentHeight="150px"
                  placeholder={intl.formatMessage({ id: 'insert check in description here...' })}
                />
              </Instruction>
              <Instruction>
                <BasicEditor
                  name="cancellationDescription"
                  value={data.cancellationDescription}
                  onChange={handleEditorCancellationDescription}
                  editorLoaded={editorLoaded}
                  maxContentHeight="150px"
                  minContentHeight="150px"
                  placeholder={intl.formatMessage({
                    id: 'insert cancellation description here...',
                  })}
                />
              </Instruction>
            </LodgingDescriptions>
          </MetaData>
          <GaleryContainer>
            <InputGallery
              editData={data && data.images?.length > 0 ? parseImages(data.images) : null}
              ref={galleryRef}
              forceColumn={true}
              handleAction={submit}
              withContent={false}
            />
          </GaleryContainer>
          <AmenitiesContainer>
            <Subtitle>{translate('Amenities')}</Subtitle>
            <Amenities />
          </AmenitiesContainer>
        </Container>
      )}
    </Root>
  );
};

export default NewLodging;
