import React, { useState } from 'react';
import styled from 'styled-components';
import { Autocomplete, TextField } from '@mui/material';

const Root = styled.div`
  width: 100%;
  background-color: transparent;
`;

const Error = styled.p`
  margin: 0;
  font-size: 90%;
  color: ${(props) => props.theme.palette.error};
`;

const EmailInput = ({ id, name, placeholder, label, editData, onChange }) => {
  const [items, setItems] = useState(editData ? editData : []);
  const [value, setValue] = useState('');
  const [error, setError] = useState(null);

  const handleAddItem = () => {
    let trimmedValue = value.trim();

    if (trimmedValue && isValid(trimmedValue)) {
      setItems([...items, trimmedValue]);
      setValue('');
      onChange([...items, trimmedValue]);
      setError(false);
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const clipboardData = e.clipboardData || window.clipboardData;
    const paste = clipboardData.getData('text');
    const emails = extractEmails(paste);

    if (emails) {
      const toBeAdded = emails.filter((email) => !isInList(email));
      setItems([...items, ...toBeAdded]);
      onChange([...items, ...toBeAdded]);
    }
  };

  const extractEmails = (text) => {
    const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;
    return text.match(emailRegex);
  };

  const isValid = (email) => {
    let error = null;

    if (isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (!isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (error) {
      setError(error);
      return false;
    }

    return true;
  };

  const isInList = (email) => {
    return items.includes(email);
  };

  const isEmail = (email) => {
    const officialRule =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return officialRule.test(email);
  };

  return (
    <Root>
      <Autocomplete
        multiple
        id={id}
        options={[]} // You can pass any autocomplete options here if needed
        value={items}
        freeSolo
        inputValue={value}
        onInputChange={(event, newValue) => {
          setValue(newValue);
        }}
        onChange={(event, newValue) => {
          if (items.length > newValue.length) {
            setItems(newValue);
            setError(false);
            onChange(newValue);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            name={name}
            label={label}
            variant="outlined"
            margin="normal"
            size="small"
            required
            placeholder={placeholder}
            onKeyDown={(e) => {
              if (['Enter', 'Tab', ','].includes(e.key)) {
                e.preventDefault();
                handleAddItem();
              }
            }}
            inputProps={{
              ...params.inputProps,
              onPaste: handlePaste,
            }}
          />
        )}
      />
      {error && <Error>{error}</Error>}
    </Root>
  );
};

export default EmailInput;
