import {
  ContainerImgWithAlt,
  ImageContainer,
  InputContainer,
  StyledTextfield,
} from './StyledCourseComponent';
import InputImage from '../inputs/inputImage';
import React from 'react';
import { useIntl } from 'react-intl';
import { ErrorMessage } from './ErrorMessage';
import { Course } from './Course';

const CardImageCourse = (props) => {
  const intl = useIntl();

  return (
    <ContainerImgWithAlt>
      <ImageContainer>
        <InputImage
          id="cardImage"
          file={props.cardMultimediaData?.url}
          onChange={props.onChange}
          onReset={props.onReset}
        />
      </ImageContainer>
      <InputContainer>
        <StyledTextfield
          id="card-alter"
          name="alter"
          onChange={props.handleChangeCardMultimediaData}
          value={props.cardMultimediaData?.alter}
          label={intl.formatMessage({ id: 'alternative text' })}
          variant="outlined"
          width="100%"
          margin="normal"
          size="small"
        />
        <ErrorMessage
          condition={() =>
            props.error.hasError &&
            props.error.fieldsError.some((error) =>
              error.isIdentifiedAs(Course.ERROR_INVALID_CARD_MULTIMEDIA_DATA_ALTER)
            )
          }
          messageId={'alter must not be empty'}
        />
      </InputContainer>
      <InputContainer>
        <StyledTextfield
          id="card-epigraph"
          name="epigraph"
          onChange={props.handleChangeCardMultimediaData}
          value={props.cardMultimediaData?.epigraph}
          label={intl.formatMessage({ id: 'epigraph text' })}
          variant="outlined"
          width="100%"
          margin="normal"
          size="small"
        />
        <ErrorMessage
          condition={() =>
            props.error.hasError &&
            props.error.fieldsError.some((error) =>
              error.isIdentifiedAs(Course.ERROR_INVALID_CARD_MULTIMEDIA_DATA_EPIGRAPH)
            )
          }
          messageId={'epigraph must not be empty'}
        />
      </InputContainer>
    </ContainerImgWithAlt>
  );
};

export default CardImageCourse;
