import React from 'react';
import styled from 'styled-components';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const StyledButtonDown = styled(ArrowDropDownIcon)`
  vertical-align: text-bottom;
  cursor: pointer;
`;

const StyledButtonUp = styled(ArrowDropUpIcon)`
  vertical-align: text-bottom;
  cursor: pointer;
`;

const OrderButton = ({ name, action }) => {
  const [order, setOrder] = React.useState('ASC');

  const changeOrder = () => {
    const newOrder = order === 'ASC' ? 'DESC' : 'ASC';
    setOrder(newOrder);
    action(name, newOrder);
  };

  return order === 'ASC' ? (
    <StyledButtonUp onClick={changeOrder} />
  ) : (
    <StyledButtonDown onClick={changeOrder} />
  );
};

export default OrderButton;
