import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
/* import axios from 'axios'; */
import { Link, useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Input from '@mui/material/Input';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import RegisterBG from '../images/registerBg.jpg';
import LoginBG from '../images/loginBg.jpg';
import { useAuth } from '../providers/authentication';
import translate from '../providers/i18n/translate';
import { Navigate } from 'react-router-dom';

/* Styled components */
const Root = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: 1fr;
  width: 100%;
  height: 100vh;
  transition: 300ms ease-in-out;
  @media (min-width: 600px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Box = styled.div`
  display: ${(props) => (props.display ? props.display : 'flex')};
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  opacity: ${(props) => (props.opacity ? props.opacity : 0)};
  transition: 700ms ease-in-out;
  background: linear-gradient(232deg, #3a3a3a 0%, #3a3a3a 30%, #707070 100%);
  color: #fff;
  font-family: 'museo-sans, sans-serif';
  @media (min-width: 600px) {
    display: flex;
  }
`;

const BoxHeader = styled.div`
  display: flex;
  width: 100%;
  height: 70px;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 50px;
  border-bottom: 1px solid #707070;
  @media (min-width: 600px) {
    padding: 0 100px;
    justify-content: center;
  }
`;

const ContentLogo = styled.div`
  display: flex;
  position: relative;
  width: 180px;
  height: 100%;
  padding: 10px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
  @media (min-width: 600px) {
    & {
      width: 300px;
      height: 100%;
    }
  }
`;

const BoxImage = styled.div`
  display: none;
  position: fixed;
  width: 50%;
  height: 100%;
  top: 0;
  left: ${(props) => (props.pos === 'left' ? 0 : '50%')};
  background: ${(props) => (props.bg ? `url(${props.bg})` : 'black')};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 999;
  transition: 1000ms ease-in-out;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media (min-width: 600px) {
    display: flex;
  }
`;

const BoxContent = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: calc(100% - 70px);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background: ${(props) => (props.background ? props.background : 'transparent')}; */

  @media (min-width: 600px) {
    background: transparent;
  }
`;

const BoxTitle = styled.h2`
  margin: 0;
  font-weight: 400;
  width: 90%;
  font-size: 4rem;
  text-align: center;
  @media (min-width: 600px) {
    width: 60%;
    text-align: left;
  }
`;

const BoxSubTitle = styled.h2`
  margin: 0;
  font-size: 1rem;
  font-weight: 400;
  width: 90%;
  text-align: center;
  @media (min-width: 600px) {
    width: 60%;
    text-align: left;
  }
`;

const Form = styled.form`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: auto;
  min-height: 300px;
  background: transparent;
  margin-top: 10px;
  input {
    color: white;
  }
  @media (min-width: 600px) {
    width: 60%;
  }
`;

const StyledTextField = styled(TextField)`
  margin: ${(props) => (props.margin ? props.margin : '15px 0!important')};
  width: 100%;
  .MuiOutlinedInput-notchedOutline {
    color: #fff !important;
  }
  fieldset {
    color: #fff;
    border-color: #fff !important;
  }
  .MuiInput-underline:before {
    border-color: rgba(255, 255, 255, 0.23) !important;
  }
  .MuiInput-underline:after {
    border-color: #fff !important;
  }
  label {
    color: #fff;
    border-color: #fff;
  }
  .MuiFormLabel-root.Mui-focused {
    color: #fff;
  }
  @media (min-width: 600px) {
    ${(props) => (props.width ? `width: ${props.width};` : '')}
  }
`;

const StyledFormControl = styled(FormControl)`
  margin: ${(props) => (props.margin ? props.margin : '15px 0!important')};
  width: 100%;
  label {
    color: #fff;
    border-color: #fff;
  }
  .MuiIconButton-root {
    color: #fff;
    border-color: #fff !important;
  }
  .MuiInput-underline:before {
    border-color: rgba(255, 255, 255, 0.23) !important;
  }
  .MuiInput-underline:after {
    border-color: #fff !important;
  }
  .MuiFormLabel-root.Mui-focused {
    color: #fff;
  }
  @media (min-width: 600px) {
    ${(props) => (props.width ? `width: ${props.width};` : '')}
  }
`;

const InputGroup = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const FooterForm = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: space-around;
  margin-top: 50px;
  @media (min-width: 600px) {
    flex-direction: row;
    height: 70px;
  }
`;

const StyledButton = styled.button`
  height: 70px;
  box-sizing: border-box;
  padding: 0 60px;
  border-radius: 25px;
  border: none;
  font-weight: 900;
  color: ${(props) => (props.color ? props.color : '#000')};
  background: ${(props) => (props.background ? props.background : 'transparent')};
  cursor: pointer;
  margin-bottom: 15px;
  @media (min-width: 600px) {
    margin-bottom: 0;
    height: 50px;
  }
`;

const RegisterAccess = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const RegisterAccessButton = styled.button`
  border: none;
  background: transparent;
  text-decoration: underline;
  cursor: pointer;
  margin: 0;
  padding: 0;
  color: #dcfe54;
  align-self: center;
`;

const Error = styled.span`
  color: red;
  opacity: 0.8;
  font-size: 1.25rem;
`;

const Login = () => {
  const error = null;

  let navigate = useNavigate();

  const { onLogin, token } = useAuth();

  const intl = useIntl();

  const [registerError, setRegisterError] = React.useState('');

  const [showPassword, setShowPassword] = React.useState({
    password: false,
    repassword: false,
    loginPassword: false,
  });

  const [value, setValue] = React.useState('right');

  const handleBoxImage = (e) => {
    e.preventDefault();
    setValue(e.target.value);
  };

  const handleClickShowPassword = (e) => {
    const { name } = e.currentTarget;
    setShowPassword({
      ...showPassword,
      [name]: !showPassword[name],
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });

  const [registerFormData, setRegisterFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    username: '',
    password: '',
    repassword: '',
    playerName: '',
  });

  const onChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const submitLogin = (e) => {
    e.preventDefault();
    onLogin({ userName: formData.username, password: formData.password }, () =>
      navigate('/admin/dashboard')
    );
  };

  const onChangeRegister = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setRegisterFormData({
      ...registerFormData,
      [name]: value,
    });
  };

  const onRegister = (e) => {
    e.preventDefault();
    /* if(registerFormData.password === registerFormData.repassword){
      if(registerFormData.registerpassword === process.env.NEXT_PUBLIC_REGISTER_PASSWORD){
        axios
        .post( `${process.env.NEXT_PUBLIC_BACKEND_SERVER}/api/users/register`, registerFormData)
        .then(response => {
          setRegisterFormData({
            firstname: '',
            lastname: '',
            documentType: 0,
            documentNumber: '',
            email: '',
            username:'',
            password:'',
            repassword:''
          });
          setValue(0);
        })
        .catch(error => {
            alert("Hubo un error en el registro...");
            console.log(error);
        })
      } else {
        alert("No tiene permiso para registrar.")
      }

    } else {
      alert("Las contraseñas no son iguales.")
    } */
  };

  return token ? (
    <Navigate to="/admin/dashboard" replace />
  ) : (
    <Root>
      <BoxImage pos={value} bg={value === 'left' ? RegisterBG : LoginBG}></BoxImage>
      <Box opacity={value === 'right' ? 1 : 0} display={value === 'right' ? 'flex' : 'none'}>
        <BoxHeader>
          <ContentLogo>
            <Link to="/">
              <img src={process.env.REACT_APP_LOGO} alt="logo" />
            </Link>
          </ContentLogo>
        </BoxHeader>
        <BoxContent background="linear-gradient(225deg, #DCFE54 0%, transparent 50%);">
          <BoxTitle>
            <FormattedMessage id="app.login.welcome" defaultMessage="Welcome" />
          </BoxTitle>
          <BoxSubTitle>
            <FormattedMessage id="app.login.loginaccount" defaultMessage="Login to your account" />
          </BoxSubTitle>
          <Form onSubmit={submitLogin}>
            {error && <Error>{error}</Error>}
            <StyledTextField
              fullWidth
              required
              id="username"
              name="username"
              label={intl.formatMessage({ id: 'username' })}
              type="text"
              value={formData.username}
              onChange={onChange}
              variant="standard"
            />
            <StyledFormControl variant="standard">
              <InputLabel htmlFor="loginpassword">{translate('password')}</InputLabel>
              <Input
                fu
                required
                id="loginpassword"
                name="password"
                type={showPassword.loginPassword ? 'text' : 'password'}
                autoComplete="current-password"
                value={formData.password}
                onChange={onChange}
                variant="standard"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      name="loginPassword"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword.loginPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </StyledFormControl>
            <FooterForm>
              <StyledButton background="#DCFE54" type="submit">
                <FormattedMessage id="app.login.signin" defaultMessage="Sign in" />
              </StyledButton>
              {/* <RegisterAccess>
                    <span>
                      <FormattedMessage
                      id="app.login.donthaveaccount"
                      defaultMessage="Still don't have an account?" />
                      </span>
                    <RegisterAccessButton value="left" onClick={handleBoxImage}>
                      <FormattedMessage
                        id="app.login.signup"
                        defaultMessage="Sign up here" />
                    </RegisterAccessButton>
                  </RegisterAccess> */}
            </FooterForm>
          </Form>
        </BoxContent>
      </Box>
      <Box opacity={value === 'right' ? 0 : 1} display={value === 'right' ? 'none' : 'flex'}>
        <BoxHeader>
          <ContentLogo>
            <Link to="/">
              <img src={process.env.REACT_APP_LOGO} alt="logo" />
            </Link>
          </ContentLogo>
        </BoxHeader>
        <BoxContent background="linear-gradient(225deg, #40CBE6 0%, transparent 50%);">
          <BoxTitle>
            <FormattedMessage id="app.register.welcome" defaultMessage="Let's start" />
          </BoxTitle>
          <BoxSubTitle>
            <FormattedMessage id="app.login.loginaccount" defaultMessage="Login to your account" />
          </BoxSubTitle>
          <Form onSubmit={onRegister}>
            {registerError !== '' && <Error>{registerError}</Error>}
            <InputGroup>
              <StyledTextField
                width="47.5%"
                required
                id="firstName"
                name="firstName"
                label="First Name"
                type="text"
                value={registerFormData.firstName}
                onChange={onChangeRegister}
                variant="standard"
              />
              <StyledTextField
                width="47.5%"
                required
                id="lastName"
                name="lastName"
                label="Last Name"
                type="text"
                value={registerFormData.lastName}
                onChange={onChangeRegister}
                variant="standard"
              />
            </InputGroup>
            <InputGroup>
              <StyledTextField
                width="47.5%"
                required
                id="email"
                name="email"
                label="Email"
                type="text"
                value={registerFormData.email}
                onChange={onChangeRegister}
                variant="standard"
              />
              <StyledTextField
                width="47.5%"
                required
                id="documentNumber"
                name="documentNumber"
                label="DNI"
                type="number"
                value={registerFormData.documentNumber}
                onChange={onChangeRegister}
                variant="standard"
              />
            </InputGroup>
            <StyledTextField
              fullWidth
              required
              id="username"
              name="username"
              label={intl.formatMessage({ id: 'username' })}
              type="text"
              value={registerFormData.username}
              onChange={onChangeRegister}
              variant="standard"
            />
            <InputGroup>
              <StyledFormControl variant="standard">
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input
                  width="47.5%"
                  required
                  id="password"
                  name="password"
                  label="Password"
                  type={showPassword.password ? 'text' : 'password'}
                  value={registerFormData.password}
                  autoComplete="current-password"
                  onChange={onChangeRegister}
                  variant="standard"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        name="password"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword.password ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </StyledFormControl>
              <StyledFormControl variant="standard">
                <InputLabel htmlFor="repassword">Repeat Password</InputLabel>
                <Input
                  width="47.5%"
                  required
                  id="repassword"
                  name="repassword"
                  label="Repeat Password"
                  type={showPassword.repassword ? 'text' : 'password'}
                  value={registerFormData.repassword}
                  onChange={onChangeRegister}
                  variant="standard"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        name="repassword"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword.repassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </StyledFormControl>
            </InputGroup>
            <FooterForm>
              <StyledButton color="#fff" background="#40CBE6" type="submit">
                <FormattedMessage id="app.register.signup" defaultMessage="Sign up" />
              </StyledButton>
              <RegisterAccess>
                <span>
                  <FormattedMessage
                    id="app.register.haveanaccount"
                    defaultMessage="Already have an account?"
                  />
                </span>
                <RegisterAccessButton value="right" onClick={handleBoxImage}>
                  <FormattedMessage id="app.register.signin" defaultMessage="Sign in here" />
                </RegisterAccessButton>
              </RegisterAccess>
            </FooterForm>
          </Form>
        </BoxContent>
      </Box>
    </Root>
  );
};

export default Login;
