import React from 'react';
import styled from 'styled-components';
import translate from '../../providers/i18n/translate';
import { ThemeContext } from '../../providers/theme';
import { Modal } from '@mui/material';
import NewItem from './newItem';
import Item from './item';

const Root = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  min-width: 350px;
  padding: 20px 0;
  height: auto;
`;
const Title = styled.div`
  position: relative;
  font-size: 1.5rem;
  width: 100%;
  color: ${(props) => props.theme.palette.details};
  border-bottom: 1px solid ${(props) => props.theme.palette.details};
  height: auto;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AddItem = styled.button`
  position: relative;
  color: ${(props) => props.theme.palette.principal};
  cursor: pointer;
  margin-right: 1rem;
`;

const Items = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${(props) => (props.forceColumn ? 'column' : 'row')};
  gap: ${(props) => (props.forceColumn ? '20px' : '50px')};
  align-items: ${(props) => (props.forceColumn ? 'center' : 'flex-start')};
  justify-content: flex-start;
  overflow-y: auto;
  flex-wrap: wrap;
  padding: 20px;
`;

const Accordion = ({ id, name, title, forceColumn, data, setData, notifyEdit }) => {
  const theme = React.useContext(ThemeContext);

  const [open, setOpen] = React.useState(false);

  const editItem = (event) => {
    const newItems = data;
    let updateItemIndex = newItems.findIndex((e) => e.id === event.id);
    newItems[updateItemIndex] = event;
    setData([...newItems]);
  };

  const addItem = (event) => {
    const newItems = [...data];
    const newItem = event;
    newItem['order'] = data.length + 1;
    newItems.push(newItem);
    setData([...newItems]);
  };

  const onOpen = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleDelete = (id) => {
    const newItems = data.filter((m) => m.id != id);
    setData([...newItems]);
    notifyEdit(name);
  };

  const compareItems = (a, b) => {
    if (a.order < b.order) {
      return -1;
    }
    if (a.order > b.order) {
      return 1;
    }
    return 0;
  };

  const handleUp = (id) => {
    const newItems = [];
    data.forEach((element) => {
      newItems.push({
        id: element.id,
        name: element.name,
        order: element.order,
        text: element.text,
      });
    });
    const indexItem = newItems.findIndex((m) => m.id === id);
    const ordertmp = newItems[indexItem - 1].order;
    newItems[indexItem - 1].order = newItems[indexItem].order;
    newItems[indexItem].order = ordertmp;
    newItems.sort(compareItems);
    setData([...newItems]);
    notifyEdit(name);
  };

  const handleDown = (id) => {
    const newItems = [];
    data.forEach((element) => {
      newItems.push({
        id: element.id,
        name: element.name,
        order: element.order,
        text: element.text,
      });
    });
    const indexItem = newItems.findIndex((m) => m.id === id);
    const ordertmp = newItems[indexItem + 1].order;
    newItems[indexItem + 1].order = newItems[indexItem].order;
    newItems[indexItem].order = ordertmp;
    newItems.sort(compareItems);
    setData([...newItems]);
    notifyEdit(name);
  };

  const handleItemData = (id, eventData) => {
    const newItems = data;
    const updateItem = newItems.find((m) => m.id === id);
    updateItem.data = eventData;
    setData([...newItems]);
    notifyEdit(name);
  };

  return (
    <Root>
      <Title>
        {title ?? translate('accordion')}
        <AddItem onClick={onOpen}>{theme.icons.add}</AddItem>
      </Title>
      <Items forceColumn={forceColumn}>
        {data?.map((e, index) => (
          <Item
            key={`event_${index}`}
            forceColumn={forceColumn}
            data={e}
            handleDelete={handleDelete}
            handleUp={handleUp}
            handleDown={handleDown}
            handleMediaData={handleItemData}
            editItem={editItem}
            first={index === 0}
            last={index === data.length - 1}
          />
        ))}
      </Items>
      <Modal open={open} onClose={onClose}>
        <NewItem handleEdit={editItem} onClose={onClose} handleNew={addItem} />
      </Modal>
    </Root>
  );
};

export default Accordion;
