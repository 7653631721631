import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import translate from '../../providers/i18n/translate';
import TextField from '@mui/material/TextField';
import { useIntl } from 'react-intl';
import BasicEditor from '../textEditors/basicEditor';
import SelectInput from '../inputs/SelectInput';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import InputImage from '../inputs/inputImage';
import axios from 'axios';
import { API_BACKEND } from '../../config';
import { useAuth } from '../../providers/authentication';
import { decodeToken } from '../../hooks/jwt';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../store/action-creators';
import { alertConstants } from '../../store/reducers/messageBar/alertConstants';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ControlledInputGallery from '../controlledInputGallery';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px;
`;

const ContainerImgWithAlt = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  grid-column: 2/3;
  grid-row: 1/6;
`;

const IconImageInputsContainer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
`;

const IconImageInputData = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  flex-direction: column;
  justify-content: flex-start;
`;

const Header = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  border-bottom: 1px solid black;
  padding-bottom: 5px;
  @media (min-width: 1024px) {
    flex-direction: row;
    height: 50px;
    padding-bottom: 0;
  }
`;

const Title = styled.h2`
  display: flex;
  width: auto;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  text-transform: uppercase;
  font-size: 1.15rem;
  @media (min-width: 1024px) {
    font-size: 1.25rem;
  }
`;

const Subtitle = styled.div`
  position: relative;
  font-size: 1.5rem;
  width: 100%;
  color: ${(props) => props.theme.palette.details};
  border-bottom: 1px solid ${(props) => props.theme.palette.details};
  height: auto;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const Actions = styled.div`
  position: relative;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: flex-end;
  @media (min-width: 1024px) {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const ActionButton = styled.button`
  background: transparent;
  border: none;
  text-transform: capitalize;
  border: 1px solid rgba(0, 0, 0, 0.5);
  padding: 8px 10px;
  cursor: pointer;
  border-radius: 8px;
  &:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 50px);
  padding: 20px 0;
  overflow-y: auto;
  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 0.7fr 0.3fr;
    grid-template-rows: 0.4fr 0.6fr;
    gap: 20px;
  }
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
`;

const GeneralData = styled(Box)`
  height: auto;
  @media (min-width: 1024px) {
    height: 100%;
  }
`;

const MetaData = styled(Box)``;

const ContentPost = styled.div`
  width: 100%;
  height: 100%;
`;

const StyledTextfield = styled(TextField)`
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : 'inherit')};
  }
`;

const ImageContainer = styled.div`
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 0 30px 0;
`;

const InputData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : 'inherit')};
  }
`;

const CheckData = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : 'inherit')};
  }
`;

const InputError = styled.span`
  width: fit-content;
  height: ${(props) => (props.show ? 'auto' : 0)};
  overflow: hidden;
  color: ${(props) => props.theme.palette.error};
  padding: ${(props) => (props.show ? '8px 10px' : 0)};
  opacity: ${(props) => (props.show ? 1 : 0)};
  border: ${(props) => props.theme.palette.error};
  background: ${(props) => `${props.theme.palette.error}10`};
  border-radius: 8px;
  margin-bottom: 10px;
  font-size: 0.875rem;
  transition: all 300ms ease-in-out;
`;

const InputGroup = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
`;

const GalleriesContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  grid-column: 1/4;
`;

const GalleryContainer = styled.div`
  width: 100%;
  height: 40%;
`;

const NewPost = () => {
  const { id } = useParams();

  const { token } = useAuth();

  const userData = decodeToken(token);

  const dispatch = useDispatch();

  const { alertMessage } = bindActionCreators(actionCreators, dispatch);

  const intl = useIntl();
  let navigate = useNavigate();
  const [editorLoaded, setEditorLoaded] = React.useState(false);

  const [mobImages, setMobImages] = React.useState([]);

  const [deskImages, setDeskImages] = React.useState([]);

  const loadImages = async (imagesToLoad, setImageState) => {
    const galleryImages = [];
    for (let index = 0; index < imagesToLoad.length; index++) {
      const res = await fetch(imagesToLoad[index].url);
      const buf = await res.arrayBuffer();
      const file = new File([buf], imagesToLoad[index].alt, {
        type: 'image/webp',
      });
      galleryImages.push({
        ...imagesToLoad[index],
        file: file,
      });
    }
    setImageState([...galleryImages]);
  };

  const parseImages = (toParse) => {
    return toParse.map((elem) => {
      return {
        id: elem.id,
        url: elem.img,
        alt: elem.alt,
        order: elem.order,
        data: elem.data,
      };
    });
  };

  const [errors, setErrors] = React.useState({
    title: {
      value: false,
      text: intl.formatMessage({ id: 'title must not be empty' }),
      validate: (value) => value == null || value === '', // returns true if there is an error and false if there is not
    },
    slug: {
      value: false,
      text: intl.formatMessage({ id: 'slug must not be empty' }),
      validate: (value) => value == null || value === '', // returns true if there is an error and false if there is not
    },
    author: {
      value: false,
      text: intl.formatMessage({ id: 'author must not be empty' }),
      validate: (value) => value == null || value === '', // returns true if there is an error and false if there is not
    },
    date: {
      value: false,
      text: intl.formatMessage({ id: 'date must not be empty' }),
      validate: (value) => value == null || value === '', // returns true if there is an error and false if there is not
    },
    img: {
      value: false,
      text: intl.formatMessage({ id: 'image must not be empty' }),
      validate: (value) => value == null || value === '', // returns true if there is an error and false if there is not
    },
    epigraph: {
      value: false,
      text: intl.formatMessage({ id: 'slug must not be empty' }),
      validate: (value) => value == null || value === '', // returns true if there is an error and false if there is not
    },
    status: {
      value: false,
      text: intl.formatMessage({
        id: 'status must not be empty',
      }),
      validate: (value) => value == null || value === '', // returns true if there is an error and false if there is not
    },
    type: {
      value: false,
      text: intl.formatMessage({
        id: 'post type must not be empty',
      }),
      validate: (value) => value == null || value === '', // returns true if there is an error and false if there is not
    },
  });

  const [errorsImg, setErrorsImg] = React.useState({
    srcSelect: {
      value: false,
      text: intl.formatMessage({ id: 'source select must not be empty' }),
      validate: (value) => value === null || value === '', // returns true if there is an error and false if there is not
    },
    img: {
      value: false,
      text: intl.formatMessage({ id: 'image must not be empty' }),
      validate: (value) => value === null || value === '', // returns true if there is an error and false if there is not
    },
  });

  const [errorsVideo, setErrorsVideo] = React.useState({
    videoType: {
      value: false,
      text: intl.formatMessage({ id: 'source video must not be empty' }),
      validate: (value) => value == null || value === '', // returns true if there is an error and false if there is not
    },
    srcVideo: {
      value: false,
      text: intl.formatMessage({ id: 'video link must not be empty' }),
      validate: (value) => value == null || value === '', // returns true if there is an error and false if there is not
    },
  });

  const [data, setData] = React.useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChangeCheck = (e) => {
    const { name, checked } = e.target;
    setData({
      ...data,
      [name]: checked,
    });
  };

  const handleEditor = (e, dataeditor) => {
    setData({
      ...data,
      content: dataeditor,
    });
  };

  const back = () => {
    navigate(-1);
  };

  const handleChangeAutocomplete = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChangeDate = (newValue) => {
    setData({
      ...data,
      date: newValue,
    });
  };

  const onChangeImage = (files) => {
    if (files.length > 0) {
      setData({
        ...data,
        img: files[0],
      });
    }
  };

  const onResetImage = () => {
    setData({
      ...data,
      img: null,
    });
  };

  const onChangeContentImage = (files) => {
    if (files.length > 0) {
      const formData = new FormData();
      formData.append('image', files[0]);
      axios
        .post(`${API_BACKEND}/sections/images`, formData, {})
        .then((res) => {
          setData({
            ...data,
            contentImage: res.data.url,
            videoType: null,
            srcVideo: '',
            idVideo: '',
          });
        })
        .catch((error) => {
          alertMessage(error.response?.data?.msg, alertConstants.ERROR);
        });
    }
  };

  const onResetContentImage = () => {
    setData({
      ...data,
      contentImage: null,
    });
  };

  const onChangeAuthorAvatar = (files) => {
    if (files.length > 0) {
      const formData = new FormData();
      formData.append('image', files[0]);
      axios
        .post(`${API_BACKEND}/sections/images`, formData, {})
        .then((res) => {
          setData({
            ...data,
            authorAvatar: res.data.url,
          });
        })
        .catch((error) => {
          alertMessage(error.response?.data?.msg, alertConstants.ERROR);
        });
    }
  };

  const onResetAuthorAvatar = () => {
    setData({
      ...data,
      authorAvatar: null,
    });
  };

  const validateForm = () => {
    let foundError = false;
    const newErrors = {};
    Object.keys(errors).forEach((err) => {
      if (!foundError) {
        foundError = errors[err].validate(data[err]);
      }
      const val = errors[err].validate(data[err]);
      newErrors[err] = {
        ...errors[err],
        value: val,
      };
      setErrors({
        ...errors,
        ...newErrors,
      });
    });
    return !foundError;
  };

  const submit = () => {
    if (validateForm()) {
      const banner = {};
      banner['mobile'] = mobImages.map((med) => {
        return {
          id: med.id,
          img: med.url,
          imgDesktop: med.url,
          imgTablet: med.url,
          imgMobile: med.url,
          alt: med.alt,
          order: med.order,
          data: med.data,
        };
      });
      banner['desktop'] = deskImages.map((med) => {
        return {
          id: med.id,
          img: med.url,
          imgDesktop: med.url,
          imgTablet: med.url,
          imgMobile: med.url,
          alt: med.alt,
          order: med.order,
          data: med.data,
        };
      });
      const formattedData = {};
      formattedData['banner'] = banner;
      formattedData['contentImage'] = data?.contentImage;
      formattedData['contentImage'] = data?.contentImage;
      formattedData['authorAvatar'] = data?.authorAvatar;
      formattedData['srcSelect'] = data?.srcSelect;
      formattedData['videoType'] = data?.videoType;
      formattedData['srcVideo'] = data?.srcVideo;
      formattedData['idVideo'] = data?.idVideo;
      const formData = new FormData();
      formData.append('title', data.title);
      formData.append('subtitle', data.subtitle);
      formData.append('content', data.content);
      formData.append('author', data.author);
      formData.append('slug', data.slug);
      formData.append('date', data.date);
      formData.append('type', data?.type?.value);
      formData.append('status', data?.status?.value);
      formData.append('action', data.action);
      formData.append('link', data.link);
      formData.append('outstanding', data.outstanding);
      formData.append('modal', data.modal);
      formData.append('data', JSON.stringify(formattedData));
      formData.append('tags', null); // TODO: agregar manejo de tags
      formData.append('images', data.img && data.img.id ? data.img.id : data.img);
      formData.append(
        'filesDatas',
        JSON.stringify([
          {
            alter: data.epigraph,
            epigraph: data.epigraph,
            order: 1,
            id: data.img.id,
          },
        ])
      );
      formData.append('user', userData?.id);

      if (id) {
        axios
          .put(`${API_BACKEND}/posts/${id}`, formData, {})
          .then((res) => {
            alertMessage(intl.formatMessage({ id: 'post updated' }), alertConstants.INFO);
            navigate(-1);
          })
          .catch((error) => {
            alertMessage(error.response?.data?.msg, alertConstants.ERROR);
          });
      } else {
        axios
          .post(`${API_BACKEND}/posts`, formData, {})
          .then((res) => {
            alertMessage(intl.formatMessage({ id: 'post created' }), alertConstants.INFO);
            navigate(-1);
          })
          .catch((error) => {
            alertMessage(error.response?.data?.msg, alertConstants.ERROR);
          });
      }
    }
  };

  React.useEffect(() => {
    if (!data) {
      if (id !== undefined && id !== null) {
        axios
          .get(`${API_BACKEND}/posts/${id}`)
          .then((res) => {
            const post = res.data;
            post.data?.banner?.mobile &&
              loadImages(parseImages(post.data.banner.mobile), setMobImages);
            post.data?.banner?.desktop &&
              loadImages(parseImages(post.data.banner.desktop), setDeskImages);
            setData({
              title: post.title,
              subtitle: post.subtitle,
              content: post.content,
              slug: post.slug,
              type: {
                label: post.type?.value,
                value: post.type?.id,
              },
              author: post.author,
              status: {
                label: post.status?.value,
                value: post.status?.id,
              },
              img: post.images && post.images.length > 0 ? post.images[0] : '',
              epigraph: post.images && post.images.length > 0 ? post.images[0].epigraph : '',
              tags: null,
              date: new Date(post.date),
              action: post.action,
              link: post.link,
              outstanding: post.outstanding,
              modal: post.modal,
              srcSelect: post.data?.srcSelect,
              videoType: post.data?.videoType,
              srcVideo: post.data?.srcVideo,
              idVideo: post.data?.idVideo,
              contentImage: post.data?.contentImage,
              contentImageAlt: post.data?.contentImageAlt,
              authorAvatar: post.data?.authorAvatar,
            });
          })
          .catch((error) => {
            alertMessage(error.response?.data?.msg, alertConstants.ERROR);
          });
      } else {
        setData({
          title: '',
          subtitle: '',
          content: '',
          slug: '',
          type: null,
          author: '',
          status: null,
          epigraph: '',
          tags: [],
          date: new Date(),
          img: '',
          action: '',
          link: '',
          outstanding: false,
          modal: false,
          contentImage: '',
          contentImageAlt: '',
          srcSelect: '',
          videoType: '',
          srcVideo: '',
          idVideo: '',
          authorAvatar: '',
        });
      }
    }
    if (data) {
      setEditorLoaded(true);
    }
  }, [data]);

  const selectOpt = [
    {
      label: intl.formatMessage({ id: 'image from file' }),
      value: 'image from file',
    },
    {
      label: intl.formatMessage({ id: 'video from URL' }),
      value: 'video from URL',
    },
  ];

  const videoOpt = [
    {
      label: intl.formatMessage({ id: 'youtube' }),
      value: 'youtube',
    },
    {
      label: intl.formatMessage({ id: 'vimeo' }),
      value: 'vimeo',
    },
  ];

  const youtubeParser = (url) => {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return match && match[7].length === 11 ? match[7] : false;
  };

  const vimeoParser = (url) => {
    var regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/;
    var match = url.match(regExp);
    return match && match[5].length >= 5 ? match[5] : false;
  };

  const onChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name === 'srcVideo' && data.videoType.value === 'youtube') {
      setData({
        ...data,
        [name]: value,
        idVideo: youtubeParser(value),
        contentImage: null,
        contentImageAlt: '',
      });
    } else if (name === 'srcVideo' && data.videoType.value === 'vimeo') {
      setData({
        ...data,
        [name]: value,
        idVideo: vimeoParser(value),
        contentImage: null,
        contentImageAlt: '',
      });
    } else {
      setData({
        ...data,
        [name]: value,
      });
    }
  };

  return (
    data && (
      <Root>
        <Header>
          <Title>{id ? translate('edit post') : translate('new post')}</Title>
          <Actions>
            <ActionButton onClick={submit}>{translate('apply')}</ActionButton>
            <ActionButton onClick={back}>{translate('cancel')}</ActionButton>
          </Actions>
        </Header>
        <Container>
          <GeneralData>
            <Subtitle>{translate('General data')}</Subtitle>
            <IconImageInputsContainer>
              <ImageContainer>
                <InputImage
                  id="icon"
                  file={data?.img && data?.img?.id ? data?.img.image : data.img}
                  onChange={onChangeImage}
                  onReset={onResetImage}
                />
                <InputError show={errors.img.value}>{errors.img.text}</InputError>
              </ImageContainer>
              <IconImageInputData>
                <StyledTextfield
                  id="title"
                  name="title"
                  onChange={handleChange}
                  value={data.title}
                  label={intl.formatMessage({ id: 'title' })}
                  variant="outlined"
                  width="100%"
                  margin="normal"
                  size="small"
                  required
                />
                <InputError show={errors.title.value}>{errors.title.text}</InputError>
                <StyledTextfield
                  id="subtitle"
                  name="subtitle"
                  onChange={handleChange}
                  value={data.subtitle}
                  label={intl.formatMessage({ id: 'subtitle' })}
                  variant="outlined"
                  width="100%"
                  margin="normal"
                  size="small"
                />
                <StyledTextfield
                  id="slug"
                  name="slug"
                  onChange={handleChange}
                  value={data.slug}
                  label={intl.formatMessage({ id: 'slug' })}
                  variant="outlined"
                  width="100%"
                  margin="normal"
                  size="small"
                  required
                  helperText={intl.formatMessage({
                    id: 'avoid accents and strange symbols',
                  })}
                />
                <InputError show={errors.slug.value}>{errors.slug.text}</InputError>
              </IconImageInputData>
            </IconImageInputsContainer>
            <ContentPost>
              <BasicEditor
                name="content"
                value={data?.content}
                onChange={handleEditor}
                editorLoaded={editorLoaded}
                maxContentHeight="400px"
                minContentHeight="300px"
                placeholder={intl.formatMessage({
                  id: 'insert content here...',
                })}
              />
            </ContentPost>
            <InputGroup>
              <StyledTextfield
                id="action"
                name="action"
                onChange={handleChange}
                value={data.action}
                label={intl.formatMessage({ id: 'button text' })}
                variant="outlined"
                width="50%"
                margin="normal"
                size="small"
              />
              <StyledTextfield
                id="link"
                name="link"
                onChange={handleChange}
                value={data.link}
                label={intl.formatMessage({ id: 'link' })}
                variant="outlined"
                width="50%"
                margin="normal"
                size="small"
              />
            </InputGroup>
          </GeneralData>
          <MetaData>
            <InputData>
              <SelectInput
                dataurl={`${API_BACKEND}/postStatus`}
                value={data.status}
                fieldlabel="value"
                fieldvalue="id"
                label="status"
                name="status"
                onChange={handleChangeAutocomplete}
                width="100%"
                margin="normal"
                size="small"
                required
              />
              <InputError show={errors.status.value}>{errors.status.text}</InputError>
            </InputData>
            <InputData>
              <SelectInput
                dataurl={`${API_BACKEND}/postType`}
                value={data.type}
                fieldlabel="value"
                fieldvalue="id"
                label="post type"
                name="type"
                onChange={handleChangeAutocomplete}
                width="100%"
                margin="normal"
                size="small"
                required
              />
              <InputError show={errors.type.value}>{errors.type.text}</InputError>
            </InputData>
            <InputData>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DateTimePicker
                  renderInput={(props) => (
                    <StyledTextfield
                      required
                      margin="normal"
                      size="small"
                      width="100%"
                      {...props}
                    />
                  )}
                  label={intl.formatMessage({ id: 'Post date' })}
                  value={data.date}
                  onChange={handleChangeDate}
                />
              </LocalizationProvider>
              <InputError show={errors.date.value}>{errors.date.text}</InputError>
            </InputData>
            <CheckData>
              <FormControlLabel
                control={
                  <Checkbox
                    name="outstanding"
                    checked={data.outstanding}
                    onChange={handleChangeCheck}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={intl.formatMessage({ id: 'outstanding' })}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="modal"
                    checked={data.modal}
                    onChange={handleChangeCheck}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label={intl.formatMessage({ id: 'modal' })}
              />
            </CheckData>
            <InputData>
              <StyledTextfield
                id="author"
                name="author"
                onChange={handleChange}
                value={data.author}
                label={intl.formatMessage({ id: 'author' })}
                variant="outlined"
                width="100%"
                margin="normal"
                size="small"
                required
              />
              <InputError show={errors.author.value}>{errors.author.text}</InputError>
            </InputData>
            <ImageContainer>
              <span>{intl.formatMessage({ id: 'author avatar' })}</span>
              <InputImage
                id="authorAvatar"
                file={data?.authorAvatar}
                onChange={onChangeAuthorAvatar}
                onReset={onResetAuthorAvatar}
              />
            </ImageContainer>

            <Subtitle>{translate('lateral multimedia')}</Subtitle>
            <ContainerImgWithAlt>
              <InputData width="70%">
                <SelectInput
                  data={selectOpt}
                  value={data?.srcSelect?.value}
                  fieldlabel="value"
                  fieldvalue="value"
                  label={intl.formatMessage({ id: 'select src' })}
                  name="srcSelect"
                  onChange={handleChangeAutocomplete}
                  width="100%"
                  margin="normal"
                  size="small"
                  required
                />
                <InputError show={errorsImg.srcSelect.value}>{errorsImg.srcSelect.text}</InputError>
              </InputData>
              {data?.srcSelect && data?.srcSelect?.value === 'image from file' && (
                <ContainerImgWithAlt>
                  <ImageContainer>
                    <InputImage
                      id="contentImage"
                      file={data?.contentImage}
                      onChange={onChangeContentImage}
                      onReset={onResetContentImage}
                    />
                  </ImageContainer>
                  <InputError show={errorsImg.img.value}>{errorsImg.img.text}</InputError>
                  <StyledTextfield
                    id="contentImageAlt"
                    name="contentImageAlt"
                    onChange={onChange}
                    value={data?.contentImageAlt}
                    label={intl.formatMessage({ id: 'alternative text' })}
                    variant="outlined"
                    width="70%"
                    margin="normal"
                    size="small"
                  />
                </ContainerImgWithAlt>
              )}
              {data?.srcSelect && data?.srcSelect?.value === 'video from URL' && (
                <InputData width="70%">
                  <SelectInput
                    data={videoOpt}
                    value={data?.videoType?.value}
                    fieldlabel="value"
                    fieldvalue="value"
                    label={intl.formatMessage({ id: 'select video source' })}
                    name="videoType"
                    onChange={handleChangeAutocomplete}
                    width="100%"
                    margin="normal"
                    size="small"
                    required
                  />
                  <InputError show={errorsVideo.videoType.value}>
                    {errorsVideo.videoType.text}
                  </InputError>
                </InputData>
              )}
              {data?.srcSelect &&
                data?.srcSelect?.value === 'video from URL' &&
                data?.videoType?.value && (
                  <InputData width="70%">
                    <StyledTextfield
                      id="srcVideo"
                      name="srcVideo"
                      onChange={onChange}
                      value={data?.srcVideo}
                      label={intl.formatMessage({ id: 'link' })}
                      variant="outlined"
                      width="100%%"
                      margin="normal"
                      size="small"
                      required
                    />
                    <InputError show={errorsVideo.srcVideo.value}>
                      {errorsVideo.srcVideo.text}
                    </InputError>
                  </InputData>
                )}
            </ContainerImgWithAlt>
            <InputData>
              <StyledTextfield
                id="epigraph"
                name="epigraph"
                onChange={handleChange}
                value={data.epigraph}
                label={intl.formatMessage({ id: 'epigraph' })}
                variant="outlined"
                width="100%"
                margin="normal"
                size="small"
                required
              />
              <InputError show={errors.epigraph.value}>{errors.epigraph.text}</InputError>
            </InputData>
          </MetaData>
          <GalleriesContainer>
            <GalleryContainer>
              <ControlledInputGallery
                id="heroBannerDeskGallery"
                name="heroBannerDeskGallery"
                title="Galería desktop del banner"
                data={deskImages}
                setData={setDeskImages}
                withContent={false}
                contentMediaType="heroBannerBigText"
              />
            </GalleryContainer>
            <GalleryContainer>
              <ControlledInputGallery
                id="heroBannerMobGallery"
                name="heroBannerMobGallery"
                title="Galería mobile del banner"
                data={mobImages}
                setData={setMobImages}
                withContent={false}
                contentMediaType="heroBannerBigText"
              />
            </GalleryContainer>
          </GalleriesContainer>
        </Container>
      </Root>
    )
  );
};

export default NewPost;
