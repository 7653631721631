import React from 'react';
import styled from 'styled-components';
import { ThemeContext } from '../../../../providers/theme';

const Root = styled.div`
  position: relative;
  width: 250px;
  height: 125px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: absolute;
  top: 0;
  left: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  background: ${(props) => props.theme.palette.base};
  border-radius: 0 8px 8px 0;
  overflow: hidden;
`;

const Button = styled.button`
  background: transparent;
  width: 20px;
  height: 20px;
  border: none;
  cursor: pointer;
  color: ${(props) => props.theme.palette.principal};
  transition: all 300ms ease-in-out;
  ${(props) => (props.forceColumn ? '' : 'transform: rotate(-90deg);')}
  &:hover {
    background: ${(props) => `${props.theme.palette.details}AA`};
    color: ${(props) => props.theme.palette.base};
  }
  svg {
    width: 100%;
    height: 100%;
  }
`;

const ContentButton = styled(Button)`
  transform: rotate(0);
`;

const MenuCard = ({
  data,
  first,
  last,
  handleDelete,
  handleUp,
  handleDown,
  handleEdit,
  handleSubmenu,
  forceColumn,
  order,
  submenu,
}) => {
  const theme = React.useContext(ThemeContext);

  const onDelete = (e) => {
    e.preventDefault();
    handleDelete(order);
  };

  const onUp = (e) => {
    e.preventDefault();
    handleUp(order);
  };

  const onDown = (e) => {
    e.preventDefault();
    handleDown(order);
  };

  const onEditContent = (e) => {
    e.preventDefault();
    handleEdit(order, data);
  };

  const onEditSubmenu = (e) => {
    e.preventDefault();
    handleSubmenu(order, data.submenus);
  };

  React.useEffect(() => {}, [data]);

  return (
    <Root>
      <Actions>
        <Button onClick={onDelete} forceColumn={true}>
          {theme.icons.close}
        </Button>
        {!first && (
          <Button onClick={onUp} forceColumn={forceColumn}>
            {theme.icons.arrowUp}
          </Button>
        )}
        {!last && (
          <Button onClick={onDown} forceColumn={forceColumn}>
            {theme.icons.arrowDown}
          </Button>
        )}
        <ContentButton onClick={onEditContent} forceColumn={forceColumn}>
          {theme.icons.text}
        </ContentButton>
        {!submenu && handleSubmenu !== undefined ? (
          <ContentButton onClick={onEditSubmenu} forceColumn={forceColumn}>
            {theme.icons.subdirectory}
          </ContentButton>
        ) : null}
      </Actions>
      {data?.name}
    </Root>
  );
};

export default MenuCard;
