import { TextField } from '@mui/material';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

const Root = styled.div`
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
`;

const Column = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const ContentTitle = styled.div`
  grid-column: 1/5;
`;

const StyledTextfield = styled(TextField)`
  width: 100%;
  @media (min-width: 1024px) {
    width: ${(props) => (props.width ? props.width : 'inherit')};
  }
`;

const FourColumnsInput = ({ data, setData }) => {
  const intl = useIntl();

  const onChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
  };

  const onChangeColumn0 = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const newColums = data.columns;
    newColums[0][name] = value;
    setData({
      ...data,
      columns: [...newColums],
    });
  };

  const onChangeColumn1 = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const newColums = data.columns;
    newColums[1][name] = value;
    setData({
      ...data,
      columns: [...newColums],
    });
  };

  const onChangeColumn2 = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const newColums = data.columns;
    newColums[2][name] = value;
    setData({
      ...data,
      columns: [...newColums],
    });
  };

  const onChangeColumn3 = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    const newColums = data.columns;
    newColums[3][name] = value;
    setData({
      ...data,
      columns: [...newColums],
    });
  };

  return (
    <Root>
      <ContentTitle>
        <StyledTextfield
          id="title"
          name="title"
          onChange={onChange}
          value={data?.title}
          label={intl.formatMessage({ id: 'principal title' })}
          variant="outlined"
          width="100%"
          margin="normal"
          size="small"
        />
      </ContentTitle>
      <Column>
        <StyledTextfield
          id="title_0"
          data-idcolumn={0}
          name="title"
          onChange={onChangeColumn0}
          value={data.columns[0].title}
          label={intl.formatMessage({ id: 'title' })}
          variant="outlined"
          width="100%"
          margin="normal"
          size="small"
        />
        <StyledTextfield
          id="text_0"
          data-idcolumn={0}
          name="text"
          onChange={onChangeColumn0}
          value={data.columns[0].text}
          label={intl.formatMessage({ id: 'text' })}
          variant="outlined"
          width="100%"
          margin="normal"
          size="small"
          multiline
        />
      </Column>
      <Column>
        <StyledTextfield
          id="title_1"
          data-idcolumn={1}
          name="title"
          onChange={onChangeColumn1}
          value={data.columns[1].title}
          label={intl.formatMessage({ id: 'title' })}
          variant="outlined"
          width="100%"
          margin="normal"
          size="small"
        />
        <StyledTextfield
          id="text_1"
          data-idcolumn={1}
          name="text"
          onChange={onChangeColumn1}
          value={data.columns[1].text}
          label={intl.formatMessage({ id: 'text' })}
          variant="outlined"
          width="100%"
          margin="normal"
          size="small"
          multiline
        />
      </Column>
      <Column>
        <StyledTextfield
          id="title_2"
          data-idcolumn={2}
          name="title"
          onChange={onChangeColumn2}
          value={data.columns[2].title}
          label={intl.formatMessage({ id: 'title' })}
          variant="outlined"
          width="100%"
          margin="normal"
          size="small"
        />
        <StyledTextfield
          id="text_2"
          data-idcolumn={2}
          name="text"
          onChange={onChangeColumn2}
          value={data.columns[2].text}
          label={intl.formatMessage({ id: 'text' })}
          variant="outlined"
          width="100%"
          margin="normal"
          size="small"
          multiline
        />
      </Column>
      <Column>
        <StyledTextfield
          id="title_3"
          data-idcolumn={3}
          name="title"
          onChange={onChangeColumn3}
          value={data.columns[3].title}
          label={intl.formatMessage({ id: 'title' })}
          variant="outlined"
          width="100%"
          margin="normal"
          size="small"
        />
        <StyledTextfield
          id="text_3"
          data-idcolumn={3}
          name="text"
          onChange={onChangeColumn3}
          value={data.columns[3].text}
          label={intl.formatMessage({ id: 'text' })}
          variant="outlined"
          width="100%"
          margin="normal"
          size="small"
          multiline
        />
      </Column>
    </Root>
  );
};

export default FourColumnsInput;
